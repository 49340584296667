<template>
	<div>
		<div class="specialSale" v-if="windowWidth>=1200">
			<div class="ss_contentDiv" :style="'background-image:' + `url(${require('@/assets/img/bg.png')})`">
				<div class="ss_cd_titleDiv">
					<div class="ss_cd_td_title">{{$t('home.specialSale.leftTitle.title')}}</div>
					<div class="ss_cd_td_text">{{$t('home.specialSale.leftTitle.text')}}</div>
					<div class="ss_cd_td_contentDiv" @click="jump1">
						<div class="ss_cd_td_cd_content">{{$t('home.specialSale.leftTitle.button')}}</div>
					</div>
				</div>
				<div class="ss_cd_right">
					<div class="ss_cd_rg_label">
						<div class="ss_cd_rg_lb_list" v-for="(item,index) in $t('home.specialSale.rightContent.labelList')"
							:key="index">
							<el-image style="" :src="require('@/assets/img/special.png')" :fit="'contain'"></el-image>
							<div class="ss_cd_rg_lb_li_text">{{item}}</div>
						</div>
					</div>
					<div class="ss_cd_rg_contentDiv">
						<div class="ss_cd_rg_cd_div" v-for="(item,index) in $t('home.specialSale.rightContent.list')"
							:key="index">
							<div class="ss_cd_rg_cd_di_conent">
								<div class="ss_cd_rg_cd_di_ct_title">{{item.title}}</div>
								<div class="ss_cd_rg_cd_di_ct_text">{{item.type == 0 ? '热销' : item.type == 1 ? '特价' : '优质'}}
								</div>
							</div>
							<div class="ss_cd_rg_cd_di_label" v-for="(item2,index2) in item.content" :key="index2">{{item2}}</div>
							<div class="ss_cd_rg_cd_di_button" @click="jump1">{{$t('home.specialSale.rightContent.button2')}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ss_flowPath">
				<div>{{$t('home.specialSale.rightContent.flowPath.title')}}</div>
				<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text1')}}</div>
				<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
				<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text2')}}</div>
				<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
				<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text3')}}</div>
				<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
				<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text4')}}</div>
				<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
				<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text5')}}</div>
				<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
				<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text6')}}</div>
				<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
				<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text7')}}</div>
			</div>
		</div>
		
		<div class="specialSale" v-if="windowWidth<1200">
			<div class="ss_contentDiv" :style="'background-image:' + `url(${require('@/assets/img/bg.png')})`">
				<div class="ss_cd_titleDiv2">
					<div class="ss_cd_td_title">{{$t('home.specialSale.leftTitle.title')}}</div>
					<div class="ss_cd_td_text">{{$t('home.specialSale.leftTitle.text')}}</div>
					
					<div class="ss_cd_right2">
						<div class="ss_cd_rg_label">
							<div class="ss_cd_rg_lb_list" v-for="(item,index) in $t('home.specialSale.rightContent.labelList')"
								:key="index">
								<el-image style="" :src="require('@/assets/img/special.png')" :fit="'contain'"></el-image>
								<div class="ss_cd_rg_lb_li_text">{{item}}</div>
							</div>
						</div>
						<div class="ss_cd_rg_contentDiv">
							<div class="ss_cd_rg_cd_div" v-for="(item,index) in $t('home.specialSale.rightContent.list')"
								:key="index">
								<div class="ss_cd_rg_cd_di_conent">
									<div class="ss_cd_rg_cd_di_ct_title">{{item.title}}</div>
									<div class="ss_cd_rg_cd_di_ct_text">{{item.type == 0 ? '热销' : item.type == 1 ? '特价' : '优质'}}
									</div>
								</div>
								<div class="ss_cd_rg_cd_di_label" v-for="(item2,index2) in item.content" :key="index2">{{item2}}
								</div>
								<div class="ss_cd_rg_cd_di_button" @click="jump1">{{$t('home.specialSale.rightContent.button2')}}</div>
							</div>
						</div>
					</div>
					
					<div class="ss_cd_td_contentDiv" @click="jump1">
						<div class="ss_cd_td_cd_content">{{$t('home.specialSale.leftTitle.button')}}</div>
					</div>
				</div>
			</div>
			<div class="ss_flowPath2">
				<div class="ss_fp_text2">{{$t('home.specialSale.rightContent.flowPath.title')}}</div>
				<div class="ss_fp_textDiv">
					<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text1')}}</div>
					<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
					<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text2')}}</div>
					<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
					<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text3')}}</div>
					<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
					<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text4')}}</div>
					<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
					<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text5')}}</div>
					<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
					<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text6')}}</div>
					<el-image class="ss_fp_img" :src="require('@/assets/img/img-18.png')" :fit="'cover'"></el-image>
					<div class="ss_fp_text">{{$t('home.specialSale.rightContent.flowPath.text7')}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {

			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//-----------------路由跳转-----------------------
			jump1:function(){
				this.$router.push({
					path: '/valuation',
				})
				this.$store.dispatch("setTopTabsIndex", {
				    topTabsIndex: 0,
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.specialSale {
			padding: 0 200px 40px 200px;

			.ss_contentDiv {
				padding: 40px;
				width: 100%;
				display: flex;
				background-size: cover;

				.ss_cd_titleDiv {
					width: 20%;

					.ss_cd_td_title {
						color: #fff;
						font-size: 32px;
						font-weight: bold;
					}

					.ss_cd_td_text {
						font-size: 20px;
						color: #fff;
						padding-top: 40px;
					}

					.ss_cd_td_contentDiv {
						position: relative;
						padding-top: 40px;
						z-index: 1;

						.ss_cd_td_cd_content {
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}

				.ss_cd_right {
					width: 80%;

					.ss_cd_rg_label {
						display: flex;
						padding-left: 40px;

						.ss_cd_rg_lb_list {
							display: flex;

							.ss_cd_rg_lb_li_text {
								width: 100%;
								color: #fff;
								font-size: 20px;
								padding-right: 20px;
							}
						}
					}

					.ss_cd_rg_contentDiv {
						display: flex;
						padding-left: 40px;
						padding-top: 30px;

						.ss_cd_rg_cd_div {
							width: 33.4%;
							background: #fff;
							padding: 20px;
							margin-right: 20px;

							.ss_cd_rg_cd_di_conent {
								display: flex;
								align-items: center;

								.ss_cd_rg_cd_di_ct_title {
									font-size: 18px;
								}

								.ss_cd_rg_cd_di_ct_text {
									font-size: 12px;
									color: #fff;
									padding: 2px 4px;
									border-radius: 3px;
									background-color: #ff9537;
									margin-left: 10px;
								}
							}

							.ss_cd_rg_cd_di_label {
								padding-top: 10px;
								color: #666;
							}

							.ss_cd_rg_cd_di_price {
								font-size: 32px;
								color: #d1102d;
								padding-top: 30px;
							}

							.ss_cd_rg_cd_di_button {
								font-size: 16px;
								color: #d1102d;
								text-align: center;
								padding: 10px;
								border: 1px solid #d1102d;
								border-radius: 40px;
								margin: 0 50px;
								margin-top: 30px;
								margin-bottom: 10px;
							}
						}
					}
				}
			}

			.ss_flowPath {
				padding: 20px;
				width: 100%;
				background-color: #6a6a6a;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 14px;

				.ss_fp_text {
					padding: 0 20px;
				}

				.ss_fp_img {
					width: 25px;
					height: 20px;
				}
			}
		}
	}

	@media only screen and (max-width:1920px) {

		.specialSale {
			padding: 0 200px 40px 200px;

			.ss_contentDiv {
				padding: 40px;
				width: 100%;
				display: flex;
				background-size: cover;

				.ss_cd_titleDiv {
					width: 20%;

					.ss_cd_td_title {
						color: #fff;
						font-size: 32px;
						font-weight: bold;
					}

					.ss_cd_td_text {
						font-size: 20px;
						color: #fff;
						padding-top: 40px;
					}

					.ss_cd_td_contentDiv {
						position: relative;
						padding-top: 40px;
						z-index: 1;

						.ss_cd_td_cd_content {
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}

				.ss_cd_right {
					width: 80%;

					.ss_cd_rg_label {
						display: flex;
						padding-left: 40px;

						.ss_cd_rg_lb_list {
							display: flex;

							.ss_cd_rg_lb_li_text {
								width: 100%;
								color: #fff;
								font-size: 20px;
								padding-right: 20px;
							}
						}
					}

					.ss_cd_rg_contentDiv {
						display: flex;
						padding-left: 40px;
						padding-top: 30px;

						.ss_cd_rg_cd_div {
							width: 33.4%;
							background: #fff;
							padding: 20px;
							margin-right: 20px;

							.ss_cd_rg_cd_di_conent {
								display: flex;
								align-items: center;

								.ss_cd_rg_cd_di_ct_title {
									font-size: 18px;
								}

								.ss_cd_rg_cd_di_ct_text {
									font-size: 12px;
									color: #fff;
									padding: 2px 4px;
									border-radius: 3px;
									background-color: #ff9537;
									margin-left: 10px;
								}
							}

							.ss_cd_rg_cd_di_label {
								padding-top: 10px;
								color: #666;
							}

							.ss_cd_rg_cd_di_price {
								font-size: 32px;
								color: #d1102d;
								padding-top: 30px;
							}

							.ss_cd_rg_cd_di_button {
								font-size: 16px;
								color: #d1102d;
								text-align: center;
								padding: 10px;
								border: 1px solid #d1102d;
								border-radius: 40px;
								margin: 0 50px;
								margin-top: 30px;
								margin-bottom: 10px;
							}
						}
					}
				}
			}

			.ss_flowPath {
				padding: 20px;
				width: 100%;
				background-color: #6a6a6a;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 14px;

				.ss_fp_text {
					padding: 0 20px;
				}

				.ss_fp_img {
					width: 25px;
					height: 20px;
				}
			}
		}

	}

	@media only screen and (max-width:1919px) and (min-width:1440px) {


		.specialSale {
			padding: 0 140px 40px 140px;

			.ss_contentDiv {
				padding: 40px;
				width: 100%;
				display: flex;
				background-size: cover;

				.ss_cd_titleDiv {
					width: 20%;

					.ss_cd_td_title {
						color: #fff;
						font-size: 32px;
						font-weight: bold;
					}

					.ss_cd_td_text {
						font-size: 20px;
						color: #fff;
						padding-top: 40px;
					}

					.ss_cd_td_contentDiv {
						position: relative;
						padding-top: 40px;
						z-index: 1;

						.ss_cd_td_cd_content {
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}

				.ss_cd_right {
					width: 80%;

					.ss_cd_rg_label {
						display: flex;
						padding-left: 40px;

						.ss_cd_rg_lb_list {
							display: flex;

							.ss_cd_rg_lb_li_text {
								width: 100%;
								color: #fff;
								font-size: 20px;
								padding-right: 20px;
							}
						}
					}

					.ss_cd_rg_contentDiv {
						display: flex;
						padding-left: 40px;
						padding-top: 30px;

						.ss_cd_rg_cd_div {
							width: 33.4%;
							background: #fff;
							padding: 20px;
							margin-right: 20px;

							.ss_cd_rg_cd_di_conent {
								display: flex;
								align-items: center;

								.ss_cd_rg_cd_di_ct_title {
									font-size: 18px;
								}

								.ss_cd_rg_cd_di_ct_text {
									font-size: 12px;
									color: #fff;
									padding: 2px 4px;
									border-radius: 3px;
									background-color: #ff9537;
									margin-left: 10px;
								}
							}

							.ss_cd_rg_cd_di_label {
								padding-top: 10px;
								color: #666;
							}

							.ss_cd_rg_cd_di_price {
								font-size: 32px;
								color: #d1102d;
								padding-top: 30px;
							}

							.ss_cd_rg_cd_di_button {
								font-size: 16px;
								color: #d1102d;
								text-align: center;
								padding: 10px;
								border: 1px solid #d1102d;
								border-radius: 40px;
								margin: 0 50px;
								margin-top: 30px;
								margin-bottom: 10px;
							}
						}
					}
				}
			}

			.ss_flowPath {
				padding: 20px;
				width: 100%;
				background-color: #6a6a6a;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 14px;

				.ss_fp_text {
					padding: 0 20px;
				}

				.ss_fp_img {
					width: 25px;
					height: 20px;
				}
			}
		}


	}

	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.specialSale {
			padding: 0 40px 40px 40px;

			.ss_contentDiv {
				padding: 40px;
				width: 100%;
				display: flex;
				background-size: cover;

				.ss_cd_titleDiv {
					width: 20%;

					.ss_cd_td_title {
						color: #fff;
						font-size: 32px;
						font-weight: bold;
					}

					.ss_cd_td_text {
						font-size: 20px;
						color: #fff;
						padding-top: 40px;
					}

					.ss_cd_td_contentDiv {
						position: relative;
						padding-top: 40px;
						z-index: 1;

						.ss_cd_td_cd_content {
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}

				.ss_cd_right {
					width: 80%;

					.ss_cd_rg_label {
						display: flex;
						padding-left: 40px;

						.ss_cd_rg_lb_list {
							display: flex;

							.ss_cd_rg_lb_li_text {
								width: 100%;
								color: #fff;
								font-size: 20px;
								padding-right: 20px;
							}
						}
					}

					.ss_cd_rg_contentDiv {
						display: flex;
						padding-left: 40px;
						padding-top: 30px;

						.ss_cd_rg_cd_div {
							width: 33.4%;
							background: #fff;
							padding: 20px;
							margin-right: 20px;

							.ss_cd_rg_cd_di_conent {
								display: flex;
								align-items: center;

								.ss_cd_rg_cd_di_ct_title {
									font-size: 18px;
								}

								.ss_cd_rg_cd_di_ct_text {
									font-size: 12px;
									color: #fff;
									padding: 2px 4px;
									border-radius: 3px;
									background-color: #ff9537;
									margin-left: 10px;
								}
							}

							.ss_cd_rg_cd_di_label {
								padding-top: 10px;
								color: #666;
							}

							.ss_cd_rg_cd_di_price {
								font-size: 32px;
								color: #d1102d;
								padding-top: 30px;
							}

							.ss_cd_rg_cd_di_button {
								font-size: 16px;
								color: #d1102d;
								text-align: center;
								padding: 10px;
								border: 1px solid #d1102d;
								border-radius: 40px;
								margin: 0 50px;
								margin-top: 30px;
								margin-bottom: 10px;
							}
						}
					}
				}
			}

			.ss_flowPath {
				padding: 20px;
				width: 100%;
				background-color: #6a6a6a;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 14px;

				.ss_fp_text {
					padding: 0 20px;
				}

				.ss_fp_img {
					width: 25px;
					height: 20px;
				}
			}
		}
	}

	@media only screen and (max-width:1199px) and (min-width:768px) {
		.specialSale {
			padding: 0 50px 40px 50px;

			.ss_contentDiv {
				padding: 40px;
				width: 100%;
				display: flex;
				background-size: cover;

				.ss_cd_titleDiv2 {
					margin: 0 auto;
					text-align: center;
				
					.ss_cd_td_title {
						color: #fff;
						font-size: 28px;
						font-weight: bold;
					}
				
					.ss_cd_td_text {
						font-size: 20px;
						color: #fff;
						padding-top: 20px;
					}
					
					.ss_cd_right2 {
						
						.ss_cd_rg_label {
							display: flex;
							flex-wrap: wrap;
							justify-content: center;
							padding-top: 20px;
					
							.ss_cd_rg_lb_list {
								display: flex;
								width: 45%;
								margin: 10px;
								text-align: left;
					
								.ss_cd_rg_lb_li_text {
									width: 100%;
									color: #fff;
									font-size: 20px;
									padding-right: 20px;
								}
							}
						}
					
						.ss_cd_rg_contentDiv {
							display: flex;
							padding-top: 30px;
							flex-wrap:wrap;
					
							.ss_cd_rg_cd_div {
								width: 100%;
								background: #fff;
								padding: 20px;
								margin: 10px;
					
								.ss_cd_rg_cd_di_conent {
									display: flex;
									align-items: center;
					
									.ss_cd_rg_cd_di_ct_title {
										font-size: 18px;
									}
					
									.ss_cd_rg_cd_di_ct_text {
										font-size: 12px;
										color: #fff;
										padding: 2px 4px;
										border-radius: 3px;
										background-color: #ff9537;
										margin-left: 10px;
									}
								}
					
								.ss_cd_rg_cd_di_label {
									padding-top: 10px;
									color: #666;
								}
					
								.ss_cd_rg_cd_di_price {
									font-size: 32px;
									color: #d1102d;
									padding-top: 30px;
								}
					
								.ss_cd_rg_cd_di_button {
									font-size: 16px;
									color: #d1102d;
									text-align: center;
									padding: 10px;
									border: 1px solid #d1102d;
									border-radius: 40px;
									margin: 0 50px;
									margin-top: 30px;
									margin-bottom: 10px;
								}
							}
						}
					}
				
					.ss_cd_td_contentDiv {
						position: relative;
						padding-top: 20px;
						z-index: 1;
				
						.ss_cd_td_cd_content {
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
			
			.ss_flowPath2 {
				padding: 20px;
				width: 100%;
				background-color: #6a6a6a;
				color: #fff;
				font-size: 14px;
			
				.ss_fp_textDiv{
					display: flex;
					justify-content: center;
					align-items: center;
					
					.ss_fp_text {
						padding: 0 20px;
					}
				}
				
				.ss_fp_text2{
					padding: 10px;
				}
			
				.ss_fp_img {
					width: 25px;
					height: 20px;
				}
			}
		}
	}

	@media only screen and (max-width:767px) {
		.specialSale {
			padding: 0 30px 40px 30px;

			.ss_contentDiv {
				padding: 40px;
				width: 100%;
				display: flex;
				background-size: cover;

				.ss_cd_titleDiv2 {
					margin: 0 auto;
					text-align: center;
				
					.ss_cd_td_title {
						color: #fff;
						font-size: 20px;
						font-weight: bold;
					}
				
					.ss_cd_td_text {
						font-size: 16px;
						color: #fff;
						padding-top: 20px;
					}
					
					.ss_cd_right2 {
						
						.ss_cd_rg_label {
							flex-wrap: wrap;
							justify-content: center;
							padding-top: 20px;
					
							.ss_cd_rg_lb_list {
								display: flex;
								margin: 10px;
								text-align: left;
					
								.ss_cd_rg_lb_li_text {
									width: 100%;
									color: #fff;
									font-size: 16px;
									padding-right: 20px;
									padding-left:10px;
								}
							}
						}
					
						.ss_cd_rg_contentDiv {
							display: flex;
							padding-top: 30px;
							flex-wrap:wrap;
					
							.ss_cd_rg_cd_div {
								width: 100%;
								background: #fff;
								padding: 20px;
								margin: 10px;
					
								.ss_cd_rg_cd_di_conent {
									display: flex;
									align-items: center;
					
									.ss_cd_rg_cd_di_ct_title {
										font-size: 18px;
									}
					
									.ss_cd_rg_cd_di_ct_text {
										font-size: 12px;
										color: #fff;
										padding: 2px 4px;
										border-radius: 3px;
										background-color: #ff9537;
										margin-left: 10px;
									}
								}
					
								.ss_cd_rg_cd_di_label {
									padding-top: 10px;
									color: #666;
								}
					
								.ss_cd_rg_cd_di_price {
									font-size: 32px;
									color: #d1102d;
									padding-top: 30px;
								}
					
								.ss_cd_rg_cd_di_button {
									font-size: 16px;
									color: #d1102d;
									text-align: center;
									padding: 10px;
									border: 1px solid #d1102d;
									border-radius: 40px;
									margin-top: 30px;
									width: 100%;
									margin-bottom: 10px;
								}
							}
						}
					}
				
					.ss_cd_td_contentDiv {
						position: relative;
						padding-top: 20px;
						z-index: 1;
				
						.ss_cd_td_cd_content {
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
			
			.ss_flowPath2 {
				padding: 20px;
				width: 100%;
				background-color: #6a6a6a;
				color: #fff;
				font-size: 14px;
				
				.ss_fp_textDiv{
					display: flex;
					justify-content: center;
					align-items: center;
					flex-wrap:wrap;
					
					.ss_fp_text {
						padding: 10px 20px;
					}
				}
				
				.ss_fp_text2{
					padding: 10px;
					text-align: center;
				}
			
				.ss_fp_img {
					width: 25px;
					height: 20px;
				}
			}
		}
	}
</style>