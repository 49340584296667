<template>
	<div class="catalogue">
		<div class="cl_contentDiv" v-for="(item,index) in $t('home.catalogue.list')">
			<div class="cl_cd_topDiv">
				<el-image class="cl_cd_td_icon" :src="item.img" :fit="'cover'"></el-image>
				<div class="cl_cd_td_title">{{item.title}}</div>
				<div class="cl_cd_td_right" style="cursor: pointer;" @click="jump(item.id)">→</div>
			</div>
			<div class="cl_cd_td_bottomDiv">
				<div class="cl_cd_td_bd_bg" :style="'background-image:' + 'url('+ bgImg + ')'">
					<div class="cl_cd_td_bd_text">{{item.text}}</div>
				</div>
				
				<div class="cl_cd_td_bd_content">
					<div>{{item.content}}</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				bgImg:process.env.VUE_APP_OSS_URL + '/home/bk_bj.png',
			}
		},
		
		created(){
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//--------------------路由跳转----------------------------
			jump:function(event){
				console.log(event)
				if(event==1){
					this.$router.push({
						path: '/valuation',
					})
					this.$store.dispatch("setTopTabsIndex", {
					    topTabsIndex: 0,
					});
				}else if(event==2){
					this.$router.push({
						path: '/valuation',
					})
					this.$store.dispatch("setTopTabsIndex", {
					    topTabsIndex: 2,
					});
				}else if(event==3){
					this.$router.push({
						path: '/valuation',
					})
					this.$store.dispatch("setTopTabsIndex", {
					    topTabsIndex: 4,
					});
				}else if(event==4){
					this.$router.push({
						path: '/valuation',
					})
					this.$store.dispatch("setTopTabsIndex", {
					    topTabsIndex: 3,
					});
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.catalogue{
			padding: 40px 200px;
			display: flex;
			// flex-wrap: wrap;
			
			.cl_contentDiv{
				width: 25%;
				background: #fff;
				margin-right: 20px;
				
				.cl_cd_topDiv{
					padding: 20px;
					display: flex;
					align-items: center;
					border-bottom: 1px solid #eee;
					position: relative;
					width: 100%;
					
					.cl_cd_td_icon{
						width: 60px;
						height: 30px;
					}
					
					.cl_cd_td_title{
						font-size: 26px;
						padding-left: 10px;
						color: #d1102d;
					}
					
					.cl_cd_td_right{
						padding: 3px 30px;
						color: #d2d2d2;
						font-size: 20px;
						border: 1px solid #d2d2d2;
						border-radius: 40px;
						position: absolute;
						right: 20px;
						top: 20px;
					}
				}
				
				.cl_cd_td_bottomDiv{
					padding: 20px;
					
					.cl_cd_td_bd_bg{
						width: 100%;
						height: 72px;
						
						.cl_cd_td_bd_text{
							color: #fff;
							font-size: 16px;
							padding: 10px;
							display: -webkit-box;
							-webkit-line-clamp:2;
							-webkit-box-orient: vertical;
							overflow: hidden;
							line-height: 28px;
						}
					}
					
					.cl_cd_td_bd_content{
						font-size: 20px;
						padding: 20px 0;
						line-height: 35px;
						height: 130px;
						display: -webkit-box;
						-webkit-line-clamp:3;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.catalogue{
			padding: 40px 200px;
			display: flex;
			// flex-wrap: wrap;
			
			.cl_contentDiv{
				width: 25%;
				background: #fff;
				margin-right: 20px;
				
				.cl_cd_topDiv{
					padding: 20px;
					display: flex;
					align-items: center;
					border-bottom: 1px solid #eee;
					position: relative;
					width: 100%;
					
					.cl_cd_td_icon{
						width: 60px;
						height: 30px;
					}
					
					.cl_cd_td_title{
						font-size: 26px;
						padding-left: 10px;
						color: #d1102d;
					}
					
					.cl_cd_td_right{
						padding: 3px 30px;
						color: #d2d2d2;
						font-size: 20px;
						border: 1px solid #d2d2d2;
						border-radius: 40px;
						position: absolute;
						right: 20px;
						top: 20px;
					}
				}
				
				.cl_cd_td_bottomDiv{
					padding: 20px;
					
					.cl_cd_td_bd_bg{
						width: 100%;
						height: 72px;
						
						.cl_cd_td_bd_text{
							color: #fff;
							font-size: 16px;
							padding: 10px;
							display: -webkit-box;
							-webkit-line-clamp:2;
							-webkit-box-orient: vertical;
							overflow: hidden;
							line-height: 28px;
						}
					}
					
					.cl_cd_td_bd_content{
						font-size: 20px;
						padding: 20px 0;
						line-height: 35px;
						height: 130px;
						display: -webkit-box;
						-webkit-line-clamp:3;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.catalogue{
			padding: 40px 140px;
			display: flex;
			flex-wrap: wrap;
			
			.cl_contentDiv{
				width: 48%;
				background: #fff;
				margin: 10px;
				
				.cl_cd_topDiv{
					padding: 20px;
					display: flex;
					align-items: center;
					border-bottom: 1px solid #eee;
					position: relative;
					width: 100%;
					
					.cl_cd_td_icon{
						width: 60px;
						height: 30px;
					}
					
					.cl_cd_td_title{
						font-size: 26px;
						padding-left: 10px;
						color: #d1102d;
					}
					
					.cl_cd_td_right{
						padding: 3px 30px;
						color: #d2d2d2;
						font-size: 20px;
						border: 1px solid #d2d2d2;
						border-radius: 40px;
						position: absolute;
						right: 20px;
						top: 20px;
					}
				}
				
				.cl_cd_td_bottomDiv{
					padding: 20px;
					
					.cl_cd_td_bd_bg{
						width: 100%;
						height: 72px;
						
						.cl_cd_td_bd_text{
							color: #fff;
							font-size: 16px;
							padding: 10px;
							display: -webkit-box;
							-webkit-line-clamp:2;
							-webkit-box-orient: vertical;
							overflow: hidden;
							line-height: 28px;
						}
					}
					
					.cl_cd_td_bd_content{
						font-size: 20px;
						padding: 20px 0;
						line-height: 35px;
						height: 130px;
						display: -webkit-box;
						-webkit-line-clamp:3;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		
			.catalogue{
				padding: 40px 16px;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				
				.cl_contentDiv{
					width: 47%;
					background: #fff;
					margin: 10px;
					
					.cl_cd_topDiv{
						padding: 20px;
						display: flex;
						align-items: center;
						border-bottom: 1px solid #eee;
						position: relative;
						width: 100%;
						
						.cl_cd_td_icon{
							width: 60px;
							height: 30px;
						}
						
						.cl_cd_td_title{
							font-size: 26px;
							padding-left: 10px;
							color: #d1102d;
						}
						
						.cl_cd_td_right{
							padding: 3px 30px;
							color: #d2d2d2;
							font-size: 20px;
							border: 1px solid #d2d2d2;
							border-radius: 40px;
							position: absolute;
							right: 20px;
							top: 20px;
						}
					}
					
					.cl_cd_td_bottomDiv{
						padding: 20px;
						
						.cl_cd_td_bd_bg{
							width: 100%;
							height: 72px;
							
							.cl_cd_td_bd_text{
								color: #fff;
								font-size: 16px;
								padding: 10px;
								display: -webkit-box;
								-webkit-line-clamp:2;
								-webkit-box-orient: vertical;
								overflow: hidden;
								line-height: 28px;
							}
						}
						
						.cl_cd_td_bd_content{
							font-size: 20px;
							padding: 20px 0;
							line-height: 35px;
							height: 130px;
							display: -webkit-box;
							-webkit-line-clamp:3;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
					}
				}
			}
		
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
				.catalogue{
					padding: 40px 40px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					
					.cl_contentDiv{
						width: 47%;
						background: #fff;
						margin: 10px;
						
						.cl_cd_topDiv{
							padding: 20px;
							display: flex;
							align-items: center;
							border-bottom: 1px solid #eee;
							position: relative;
							width: 100%;
							
							.cl_cd_td_icon{
								width: 60px;
								height: 30px;
							}
							
							.cl_cd_td_title{
								font-size: 26px;
								padding-left: 10px;
								color: #d1102d;
							}
							
							.cl_cd_td_right{
								padding: 3px 30px;
								color: #d2d2d2;
								font-size: 20px;
								border: 1px solid #d2d2d2;
								border-radius: 40px;
								position: absolute;
								right: 20px;
								top: 20px;
							}
						}
						
						.cl_cd_td_bottomDiv{
							padding: 20px;
							
							.cl_cd_td_bd_bg{
								width: 100%;
								height: 72px;
								
								.cl_cd_td_bd_text{
									color: #fff;
									font-size: 16px;
									padding: 10px;
									display: -webkit-box;
									-webkit-line-clamp:2;
									-webkit-box-orient: vertical;
									overflow: hidden;
									line-height: 28px;
								}
							}
							
							.cl_cd_td_bd_content{
								font-size: 20px;
								padding: 20px 0;
								line-height: 35px;
								height: 130px;
								display: -webkit-box;
								-webkit-line-clamp:3;
								-webkit-box-orient: vertical;
								overflow: hidden;
							}
						}
					}
				}
			
		
	}
	
	@media only screen and (max-width:767px) {
		
					.catalogue{
						padding: 40px 20px;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						
						.cl_contentDiv{
							width: 100%;
							background: #fff;
							margin: 10px;
							
							.cl_cd_topDiv{
								padding: 20px;
								display: flex;
								align-items: center;
								border-bottom: 1px solid #eee;
								position: relative;
								width: 100%;
								
								.cl_cd_td_icon{
									width: 60px;
									height: 30px;
								}
								
								.cl_cd_td_title{
									font-size: 16px;
									padding-left: 10px;
									color: #d1102d;
								}
								
								.cl_cd_td_right{
									padding: 3px 30px;
									color: #d2d2d2;
									font-size: 20px;
									border: 1px solid #d2d2d2;
									border-radius: 40px;
									position: absolute;
									right: 20px;
									top: 20px;
								}
							}
							
							.cl_cd_td_bottomDiv{
								padding: 20px;
								
								.cl_cd_td_bd_bg{
									width: 100%;
									height: 72px;
									
									.cl_cd_td_bd_text{
										color: #fff;
										font-size: 16px;
										padding: 10px;
										display: -webkit-box;
										-webkit-line-clamp:2;
										-webkit-box-orient: vertical;
										overflow: hidden;
										line-height: 28px;
									}
								}
								
								.cl_cd_td_bd_content{
									font-size: 20px;
									padding: 20px 0;
									line-height: 35px;
									height: 130px;
									display: -webkit-box;
									-webkit-line-clamp:3;
									-webkit-box-orient: vertical;
									overflow: hidden;
								}
							}
						}
					}
				
			
		
	}
</style>