<template>
	<div>
		<div class="aboutUsDiv" v-if="windowWidth>=1200">
			<div class="aud_div">
				<div class="aud_di_title">{{$t('home.aboutUs.title')}}</div>
				<div class="aud_di_text">{{$t('home.aboutUs.text')}}</div>
				
				<div style="display: flex;">
					<div class="aut_di_content1">
						<div class="aut_di_ct1_title">{{$t('home.aboutUs.content1.title')}}</div>
						<div class="aut_di_ct1_text">200<div class="aut_di_ct1_te_text">{{$t('home.aboutUs.content1.text')}}</div></div>
					</div>
					
					<div class="aut_di_content2">
						<div class="aut_di_ct2_title">{{$t('home.aboutUs.content2.title')}}</div>
						<div class="aut_di_ct2_text">650,000<div class="aut_di_ct2_te_text">{{$t('home.aboutUs.content2.text')}}</div></div>
					</div>
				</div>
				
				<div style="display: flex;">
					<div class="aut_di_content1">
						<div class="aut_di_ct1_title">{{$t('home.aboutUs.content3.title')}}</div>
						<div class="aut_di_ct1_text">3000<div class="aut_di_ct1_te_text">{{$t('home.aboutUs.content1.text')}}</div></div>
					</div>
					
					<div class="aut_di_content2">
						<div class="aut_di_ct2_title">{{$t('home.aboutUs.content4.title')}}</div>
						<div class="aut_di_ct2_text">500<div class="aut_di_ct2_te_text">{{$t('home.aboutUs.content4.text')}}</div></div>
					</div>
				</div>
			</div>
			<div class="aud_button" @click="jump">{{$t('home.aboutUs.button')}} ></div>
			<el-image class="aud_map" :src="require('@/assets/img/map.png')" :fit="'cover'"></el-image>
		</div>
		
		<div class="aboutUsDiv2" v-if="windowWidth<=1199">
			<div class="aud_div">
				<div class="aud_di_title">{{$t('home.aboutUs.title')}}</div>
				<div class="aud_di_text">{{$t('home.aboutUs.text')}}</div>
				
				<div style="display: flex;justify-content: center;padding-top: 20px;">
					<div>
						<div style="display: flex;">
							<div class="aut_di_content1">
								<div class="aut_di_ct1_title">{{$t('home.aboutUs.content1.title')}}</div>
								<div class="aut_di_ct1_text">200<div class="aut_di_ct1_te_text">{{$t('home.aboutUs.content1.text')}}</div></div>
							</div>
							
							<div class="aut_di_content2">
								<div class="aut_di_ct2_title">{{$t('home.aboutUs.content2.title')}}</div>
								<div class="aut_di_ct2_text">650,000<div class="aut_di_ct2_te_text">{{$t('home.aboutUs.content2.text')}}</div></div>
							</div>
						</div>
						
						<div style="display: flex;">
							<div class="aut_di_content1">
								<div class="aut_di_ct1_title">{{$t('home.aboutUs.content3.title')}}</div>
								<div class="aut_di_ct1_text">3000<div class="aut_di_ct1_te_text">{{$t('home.aboutUs.content1.text')}}</div></div>
							</div>
							
							<div class="aut_di_content2">
								<div class="aut_di_ct2_title">{{$t('home.aboutUs.content4.title')}}</div>
								<div class="aut_di_ct2_text">500<div class="aut_di_ct2_te_text">{{$t('home.aboutUs.content4.text')}}</div></div>
							</div>
						</div>
						
						<div style="display: flex;justify-content: center;" @click="jump">
							<div class="aud_button">{{$t('home.aboutUs.button')}} ></div>
						</div>
					</div>
				</div>
			</div>
			<el-image class="aud_map" :src="require('@/assets/img/map.png')" :fit="'cover'"></el-image>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//---------------路由跳转------------------
			jump:function(){
				this.$router.push({
					path: '/aboutUs/aboutUs',
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.aboutUsDiv{
			padding: 20px 200px;
			background: #fff;
			height: 600px;
			position: relative;
			
			.aud_div{
				position: relative;
				z-index: 5;
				padding-top: 40px;
				
				.aud_di_title{
					font-size: 32px;
				}
				
				.aud_di_text{
					font-size: 24px;
					padding-top: 10px;
					margin-bottom: 20px;
				}
				
				.aut_di_content1{
					border-top: 1px solid #e4e4e4;
					width: 200px;
					
					.aut_di_ct1_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct1_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct1_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
				
				.aut_di_content2{
					border-top: 1px solid #e4e4e4;
					width: 250px;
					margin-left: 40px;
					
					.aut_di_ct2_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct2_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct2_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
			}
			
			.aud_button{
				padding: 14px 40px;
				border-radius: 5px;
				border: 1px solid #d1102d;
				font-size: 14px;
				color: #d1102d;
				margin-top: 40px;
				width: 200px;
				text-align: center;
				cursor: pointer;
			}
			
			.aud_map{
				position: absolute;
				right: 200px;
				width: 800px;
				height: 500px;
				z-index: 4;
				top: 40px;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.aboutUsDiv{
			padding: 20px 200px;
			background: #fff;
			height: 600px;
			position: relative;
			
			.aud_div{
				position: relative;
				z-index: 5;
				padding-top: 40px;
				
				.aud_di_title{
					font-size: 32px;
				}
				
				.aud_di_text{
					font-size: 24px;
					padding-top: 10px;
					margin-bottom: 20px;
				}
				
				.aut_di_content1{
					border-top: 1px solid #e4e4e4;
					width: 200px;
					
					.aut_di_ct1_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct1_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct1_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
				
				.aut_di_content2{
					border-top: 1px solid #e4e4e4;
					width: 250px;
					margin-left: 40px;
					
					.aut_di_ct2_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct2_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct2_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
			}
			
			.aud_button{
				padding: 14px 40px;
				border-radius: 5px;
				border: 1px solid #d1102d;
				font-size: 14px;
				color: #d1102d;
				margin-top: 40px;
				width: 200px;
				text-align: center;
				cursor: pointer;
			}
			
			.aud_map{
				position: absolute;
				right: 200px;
				width: 800px;
				height: 500px;
				z-index: 4;
				top: 40px;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.aboutUsDiv{
			padding: 20px 140px;
			background: #fff;
			height: 600px;
			position: relative;
			
			.aud_div{
				position: relative;
				z-index: 5;
				padding-top: 40px;
				
				.aud_di_title{
					font-size: 32px;
				}
				
				.aud_di_text{
					font-size: 24px;
					padding-top: 10px;
					margin-bottom: 20px;
				}
				
				.aut_di_content1{
					border-top: 1px solid #e4e4e4;
					width: 200px;
					
					.aut_di_ct1_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct1_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct1_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
				
				.aut_di_content2{
					border-top: 1px solid #e4e4e4;
					width: 250px;
					margin-left: 40px;
					
					.aut_di_ct2_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct2_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct2_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
			}
			
			.aud_button{
				padding: 14px 40px;
				border-radius: 5px;
				border: 1px solid #d1102d;
				font-size: 14px;
				color: #d1102d;
				margin-top: 40px;
				width: 200px;
				text-align: center;
				cursor: pointer;
			}
			
			.aud_map{
				position: absolute;
				right: 140px;
				width: 600px;
				height: 400px;
				z-index: 4;
				top: 40px;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.aboutUsDiv{
			padding: 20px 30px;
			background: #fff;
			height: 600px;
			position: relative;
			
			.aud_div{
				position: relative;
				z-index: 5;
				padding-top: 40px;
				
				.aud_di_title{
					font-size: 32px;
				}
				
				.aud_di_text{
					font-size: 24px;
					padding-top: 10px;
					margin-bottom: 20px;
				}
				
				.aut_di_content1{
					border-top: 1px solid #e4e4e4;
					width: 200px;
					
					.aut_di_ct1_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct1_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct1_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
				
				.aut_di_content2{
					border-top: 1px solid #e4e4e4;
					width: 250px;
					margin-left: 40px;
					
					.aut_di_ct2_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct2_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct2_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
			}
			
			.aud_button{
				padding: 14px 40px;
				border-radius: 5px;
				border: 1px solid #d1102d;
				font-size: 14px;
				color: #d1102d;
				margin-top: 40px;
				width: 200px;
				text-align: center;
				cursor: pointer;
			}
			
			.aud_map{
				position: absolute;
				right: 30px;
				width: 600px;
				height: 400px;
				z-index: 4;
				top: 40px;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.aboutUsDiv2{
			padding: 20px 30px;
			background: #fff;
			position: relative;
			text-align: center;
			
			.aud_div{
				position: relative;
				z-index: 5;
				padding-top: 40px;
				
				.aud_di_title{
					font-size: 32px;
				}
				
				.aud_di_text{
					font-size: 24px;
					padding-top: 10px;
					margin-bottom: 20px;
				}
				
				.aut_di_content1{
					border-top: 1px solid #e4e4e4;
					width: 200px;
					
					.aut_di_ct1_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct1_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct1_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
				
				.aut_di_content2{
					border-top: 1px solid #e4e4e4;
					width: 250px;
					margin-left: 40px;
					
					.aut_di_ct2_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct2_text{
						color: #777;
						padding-top: 10px;
						font-size: 42px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct2_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 70px;
							padding-left: 10px;
						}
					}
				}
			}
			
			.aud_map{
				width: 600px;
				height: 400px;
				z-index: 4;
			}
		}
		
		.aud_button{
			padding: 14px 40px;
			border-radius: 5px;
			border: 1px solid #d1102d;
			font-size: 14px;
			color: #d1102d;
			margin-top: 40px;
			width: 200px;
			text-align: center;
			cursor: pointer;
		}
	}
	
	@media only screen and (max-width:767px) {
		.aboutUsDiv2{
			padding: 20px 30px;
			background: #fff;
			position: relative;
			text-align: center;
			
			.aud_div{
				position: relative;
				z-index: 5;
				padding-top: 40px;
				
				.aud_di_title{
					font-size: 32px;
				}
				
				.aud_di_text{
					font-size: 24px;
					padding-top: 10px;
					margin-bottom: 20px;
				}
				
				.aut_di_content1{
					border-top: 1px solid #e4e4e4;
					
					.aut_di_ct1_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct1_text{
						color: #777;
						padding-top: 10px;
						font-size: 30px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct1_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 50px;
							padding-left: 10px;
						}
					}
				}
				
				.aut_di_content2{
					border-top: 1px solid #e4e4e4;
					margin-left: 40px;
					
					.aut_di_ct2_title{
						color: #777;
						padding-top: 20px;
					}
					
					.aut_di_ct2_text{
						color: #777;
						padding-top: 10px;
						font-size: 30px;
						font-weight: bold;
						display: flex;
						
						.aut_di_ct2_te_text{
							font-size: 14px;
							height: 100%;
							line-height: 50px;
							padding-left: 10px;
						}
					}
				}
			}
			
			.aud_map{
				width: 100%;
				z-index: 4;
			}
		}
		
		.aud_button{
			padding: 14px 40px;
			border-radius: 5px;
			border: 1px solid #d1102d;
			font-size: 14px;
			color: #d1102d;
			margin-top: 40px;
			width: 200px;
			text-align: center;
			cursor: pointer;
		}
	}
</style>