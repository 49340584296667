<template>
	<div id="app">
		<div class="accordion1" v-if="windowWidth>=1200">
			<div class="ar_title">{{$t('home.accordion.title')}}</div>
			<div class="ar_text">{{$t('home.accordion.text')}}</div>
			<div class="shell">
				<div class="box" style="border-right: 10px solid #fff;">
					<div class="box_content">
						<img style="width: 80px;height: 26px;" src="../../../assets/img/special-processicon@icon.png" alt="">
						<div class="box_ct_title">{{$t('home.accordion.content1.title')}}</div>
						<div class="box_ct_content" style="">
							<div style="padding-top: 10px;">{{$t('home.accordion.content1.content')}}</div>
							<div style="padding-top: 20px;">{{$t('home.accordion.content1.label1')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content1.label2')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content1.label3')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content1.label4')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content1.label5')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content1.label6')}}</div>
							<div style="position: relative;padding-top:20px;" @click="jump1">
								<div style="padding: 10px 40px;cursor: pointer;display: inline-block;font-size: 16px;border: 1px solid #d1102d;background: #d1102d;color: #fff;box-shadow: 0 5px 7px rgb(255 104 34 / 28%);border-radius: 100px;">{{$t('home.accordion.button')}}</div>
							</div>
						</div>
					</div>
					<div class="mask"></div>
					<img src="../../../assets/img/special-process1@img.jpg" alt="">
				</div>
				<div class="box" style="border-right: 10px solid #fff;">
					<div class="box_content">
						<img style="width: 80px;height: 26px;" src="../../../assets/img/special-processicon@icon.png" alt="">
						<div class="box_ct_title">{{$t('home.accordion.content2.title')}}</div>
						<div class="box_ct_content" style="width: 800px;">
							<div style="">{{$t('home.accordion.content2.label1')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content2.label2')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content2.label3')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content2.label4')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content2.label5')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content2.label6')}}</div>
							<div style="position: relative;padding-top:20px;" @click="jump1">
								<div style="padding: 10px 40px;cursor: pointer;display: inline-block;font-size: 16px;border: 1px solid #d1102d;background: #d1102d;color: #fff;box-shadow: 0 5px 7px rgb(255 104 34 / 28%);border-radius: 100px;">{{$t('home.accordion.button')}}</div>
							</div>
						</div>
					</div>
					<div class="mask"></div>
					<img src="../../../assets/img/special-process2@img.jpg" alt="">
				</div>
				<div class="box" style="border-right: 10px solid #fff;">
					<div class="box_content">
						<img style="width: 80px;height: 26px;" src="../../../assets/img/special-processicon@icon.png" alt="">
						<div class="box_ct_title">{{$t('home.accordion.content3.title')}}</div>
						<div class="box_ct_content" style="width: 800px;">
							<div style="">{{$t('home.accordion.content3.label1')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content3.label2')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content3.label3')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content3.label4')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content3.label5')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content3.label6')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content3.label7')}}</div>
							<div style="position: relative;padding-top:20px;" @click="jump1">
								<div style="padding: 10px 40px;cursor: pointer;display: inline-block;font-size: 16px;border: 1px solid #d1102d;background: #d1102d;color: #fff;box-shadow: 0 5px 7px rgb(255 104 34 / 28%);border-radius: 100px;">{{$t('home.accordion.button')}}</div>
							</div>
						</div>
					</div>
					<div class="mask"></div>
					<img src="../../../assets/img/special-process3@img.jpg" alt="">
				</div>
				<div class="box">
					<div class="box_content">
						<img style="width: 80px;height: 26px;" src="../../../assets/img/special-processicon@icon.png" alt="">
						<div class="box_ct_title">{{$t('home.accordion.content4.title')}}</div>
						<div class="box_ct_content" style="width: 800px;">
							<div style="">{{$t('home.accordion.content4.label1')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content4.label2')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content4.label3')}}</div>
							<div style="padding-top: 10px;">{{$t('home.accordion.content4.label4')}}</div>
							<div style="position: relative;padding-top:20px;" @click="jump1">
								<div style="padding: 10px 40px;cursor: pointer;display: inline-block;font-size: 16px;border: 1px solid #d1102d;background: #d1102d;color: #fff;box-shadow: 0 5px 7px rgb(255 104 34 / 28%);border-radius: 100px;">{{$t('home.accordion.button')}}</div>
							</div>
						</div>
					</div>
					<div class="mask"></div>
					<img src="../../../assets/img/special-process4@img.jpg" alt="">
				</div>
			</div>
		</div>
		
		<div class="accordion2" v-if="windowWidth<1200">
			<div class="ar_title">{{$t('home.accordion.title')}}</div>
			<div class="ar_text">{{$t('home.accordion.text')}}</div>
			<div>
				<div class="ar_contentDiv">
					<div class="ar_cd_div">
						<img class="ar_cd_di_icon" src="../../../assets/img/special-processicon@icon.png" alt="">
						<div class="ar_cd_di_text2">{{$t('home.accordion.content1.title')}}</div>
						<div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content1.content')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content1.label1')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content1.label2')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content1.label3')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content1.label4')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content1.label5')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content1.label6')}}</div>
							<div class="ar_cd_di_button" @click="jump1">
								<div class="buttonDiv">{{$t('home.accordion.button')}}</div>
							</div>
						</div>
					</div>
					<!-- <div class="mask"></div> -->
					<el-image style="height: 500px;width: 100%;" :src="require('../../../assets/img/special-process1@img.jpg')" :fit="'cover'"></el-image>
				</div>
			</div>
			
			<div style="padding-top: 20px;">
				<div class="ar_contentDiv">
					<div class="ar_cd_div">
						<img class="ar_cd_di_icon" src="../../../assets/img/special-processicon@icon.png" alt="">
						<div class="ar_cd_di_text2">{{$t('home.accordion.content2.title')}}</div>
						<div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content2.label1')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content2.label2')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content2.label3')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content2.label4')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content2.label5')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content2.label6')}}</div>
							<div class="ar_cd_di_button" @click="jump1">
								<div class="buttonDiv">{{$t('home.accordion.button')}}</div>
							</div>
						</div>
					</div>
					<!-- <div class="mask"></div> -->
					<el-image style="height: 500px;width: 100%;" :src="require('../../../assets/img/special-process2@img.jpg')" :fit="'cover'"></el-image>
				</div>
			</div>
			
			<div style="padding-top: 20px;">
				<div class="ar_contentDiv">
					<div class="ar_cd_div">
						<img class="ar_cd_di_icon" src="../../../assets/img/special-processicon@icon.png" alt="">
						<div class="ar_cd_di_text2">{{$t('home.accordion.content3.title')}}</div>
						<div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content3.label1')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content3.label2')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content3.label3')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content3.label4')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content3.label5')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content3.label6')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content3.label7')}}</div>
							<div class="ar_cd_di_button" @click="jump1">
								<div class="buttonDiv">{{$t('home.accordion.button')}}</div>
							</div>
						</div>
					</div>
					<!-- <div class="mask"></div> -->
					<el-image style="height: 500px;width: 100%;" :src="require('../../../assets/img/special-process3@img.jpg')" :fit="'cover'"></el-image>
				</div>
			</div>
			
			<div style="padding-top: 20px;">
				<div class="ar_contentDiv">
					<div class="ar_cd_div">
						<img class="ar_cd_di_icon" src="../../../assets/img/special-processicon@icon.png" alt="">
						<div class="ar_cd_di_text2">{{$t('home.accordion.content4.title')}}</div>
						<div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content4.label1')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content4.label2')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content4.label3')}}</div>
							<div class="ar_cd_di_text">{{$t('home.accordion.content4.label4')}}</div>
							<div class="ar_cd_di_button" @click="jump1">
								<div class="buttonDiv">{{$t('home.accordion.button')}}</div>
							</div>
						</div>
					</div>
					<!-- <div class="mask"></div> -->
					<el-image style="height: 500px;width: 100%;" :src="require('../../../assets/img/special-process4@img.jpg')" :fit="'cover'"></el-image>
				</div>
			</div>
		</div>
	</div>

</template>



<script>
	export default {
		name: 'App',
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {

			}
		},
		created() {
			
		},
		mounted() {
			// console.log("accordion",this.windowWidth)
		},

		components: {

		},

		methods: {
			//-----------------路由跳转-----------------------
			jump1:function(){
				this.$router.push({
					path: '/valuation',
				})
				this.$store.dispatch("setTopTabsIndex", {
				    topTabsIndex: 0,
				});
			}
		}

	}
</script>



<style lang="less" scoped>
	#app {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	@media only screen and (min-width:1920px) {
		.accordion1{
			
			.ar_title{
				font-size: 32px;
				margin-left: 200px;
			}
			
			.ar_text{
				font-size: 16px;
				padding-top: 10px;
				margin-left: 200px;
				margin-bottom: 20px;
			}
		}
		
		.shell {
			padding: 0 200px 40px 200px;
			height: 500px;
			display: flex;
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 20px;
			padding-left: 20px;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
		}
		
		.box:hover .box_ct_title{
			
		}
		
		.box_ct_content{
			padding-top: 20px;
			color: #fff;
			display: none;
			animation: myfirst .5s;
			padding-right: 20px;
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.box:hover .box_ct_content{
			display: block;
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: rgba(0, 0, 0, 0.3);
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			display: none;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.accordion1{
			
			.ar_title{
				font-size: 32px;
				margin-left: 200px;
			}
			
			.ar_text{
				font-size: 16px;
				padding-top: 10px;
				margin-left: 200px;
				margin-bottom: 20px;
			}
		}
		
		.shell {
			padding: 0 200px 40px 200px;
			height: 500px;
			display: flex;
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 20px;
			padding-left: 20px;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
		}
		
		.box:hover .box_ct_title{
			
		}
		
		.box_ct_content{
			padding-top: 20px;
			color: #fff;
			display: none;
			animation: myfirst .5s;
			padding-right: 20px;
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.box:hover .box_ct_content{
			display: block;
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: rgba(0, 0, 0, 0.3);
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			display: none;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.accordion1{
			
			.ar_title{
				font-size: 32px;
				margin-left: 140px;
			}
			
			.ar_text{
				font-size: 16px;
				padding-top: 10px;
				margin-left: 140px;
				margin-bottom: 20px;
			}
		}
		
		.shell {
			padding: 0 140px 40px 140px;
			height: 500px;
			display: flex;
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 20px;
			padding-left: 20px;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
		}
		
		.box:hover .box_ct_title{
			
		}
		
		.box_ct_content{
			padding-top: 20px;
			color: #fff;
			display: none;
			animation: myfirst .5s;
			padding-right: 20px;
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.box:hover .box_ct_content{
			display: block;
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: rgba(0, 0, 0, 0.3);
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			display: none;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.accordion1{
			
			.ar_title{
				font-size: 32px;
				margin-left: 30px;
			}
			
			.ar_text{
				font-size: 16px;
				padding-top: 10px;
				margin-left: 30px;
				margin-bottom: 20px;
			}
		}
		
		.shell {
			padding: 0 30px 40px 30px;
			height: 500px;
			display: flex;
		}
		
		.box {
			flex: 1;
			overflow: hidden;
			position: relative;
			transition: .5s;
			background: #fff;
		
		}
		
		.box_content{
			position: absolute;
			z-index: 5;
			padding-top: 20px;
			padding-left: 20px;
		}
		
		.box_ct_title{
			color: #fff;
			font-size: 24px;
			font-weight: bold;
			transition: all .5s;
		}
		
		.box:hover .box_ct_title{
			
		}
		
		.box_ct_content{
			padding-top: 20px;
			color: #fff;
			display: none;
			animation: myfirst .5s;
			padding-right: 20px;
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.box:hover .box_ct_content{
			display: block;
		}
		
		.box>img {
			width: 100%;
			height: 100%;
			/* 对图片进行剪切，保留原始比例 */
			object-fit: cover;
			transition: .5s;
		}
		
		.box>span {
			font: 200 45px '优设标题黑';
			text-align: center;
			height: 15%;
			display: flex;
			justify-content: center;
			align-content: center;
		}
		
		.box:hover {
			flex-basis: 40%;
		}
		
		.box:hover>img {
			width: 100%;
			height: 100%;
		
		}
		
		.mask {
			z-index: 4;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background-color: rgba(0, 0, 0, 0.3);
			position: absolute;
			transition: background-color ease 2s;
		}
		
		.box:hover .mask{
			display: none;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.accordion2{
			
			.ar_title{
				font-size: 21px;
				text-align: center;
			}
			
			.ar_text{
				font-size: 16px;
				padding-top: 10px;
				margin-left: 30px;
				margin-bottom: 20px;
				text-align: center;
			}
			
			.ar_contentDiv{
				border-right: 10px solid #fff;
				position: relative;
				
				.ar_cd_div{
					position: absolute;
					top: 0;
					left: 0;
					z-index: 4;
					padding: 20px;
					color: #fff;
					
					.ar_cd_di_icon{
						width: 80px;
						height: 26px;
					}
					
					.ar_cd_di_text{
						padding-top: 10px;
					}
					
					.ar_cd_di_text2{
						padding-top: 20px;
					}
					
					.ar_cd_di_button{
						position: relative;
						padding-top:20px;
						
						.buttonDiv{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.accordion2{
			
			.ar_title{
				font-size: 21px;
				text-align: center;
			}
			
			.ar_text{
				font-size: 16px;
				padding-top: 10px;
				margin-left: 30px;
				margin-bottom: 20px;
				text-align: center;
			}
			
			.ar_contentDiv{
				border-right: 10px solid #fff;
				position: relative;
				
				.ar_cd_div{
					position: absolute;
					top: 0;
					left: 0;
					z-index: 4;
					padding: 20px;
					color: #fff;
					
					.ar_cd_di_icon{
						width: 80px;
						height: 26px;
					}
					
					.ar_cd_di_text{
						padding-top: 10px;
					}
					
					.ar_cd_di_text2{
						padding-top: 20px;
					}
					
					.ar_cd_di_button{
						position: relative;
						padding-top:20px;
						
						.buttonDiv{
							padding: 10px 40px;
							cursor: pointer;
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d1102d;
							background: #d1102d;
							color: #fff;
							box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
							border-radius: 100px;
						}
					}
				}
			}
		}
	}
	
</style>
