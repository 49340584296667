<template>
	<div style="background: #f3f5f7;">
		<div :style="windowWidth <= 1199 ? 'margin-bottom: 50px;' : ''">
			<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
			<right v-if="windowWidth>=1200"></right>
			<carousel :windowWidth="windowWidth" :windowHeight="windowHeight"></carousel>
			<catalogue :windowWidth="windowWidth" :windowHeight="windowHeight"></catalogue>
			<specialSale :windowWidth="windowWidth" :windowHeight="windowHeight"></specialSale>
			<service :windowWidth="windowWidth" :windowHeight="windowHeight"></service>
			<accordion :windowWidth="windowWidth" :windowHeight="windowHeight"></accordion>
			<about-us :windowWidth="windowWidth" :windowHeight="windowHeight"></about-us>
			<sunning-sheet :windowWidth="windowWidth" :windowHeight="windowHeight"></sunning-sheet>
			<news :windowWidth="windowWidth" :windowHeight="windowHeight"></news>
			<bottom :windowWidth="windowWidth" :windowHeight="windowHeight" v-if="windowWidth>=1200"></bottom>
		</div>
		<applet-bottom :windowWidth="windowWidth" :windowHeight="windowHeight" v-if="windowWidth<=768"></applet-bottom>
	</div>
</template>

<script>
	import {
		scrollTo
	} from '@/utils/scroll-to';
	import top from '@/components/top/index.vue';
	import right from '@/components/right/index.vue';
	import carousel from './components/carousel.vue';
	import catalogue from './components/catalogue.vue';
	import specialSale from './components/specialSale.vue';
	import service from './components/service.vue';
	import accordion from './components/accordion.vue';
	import aboutUs from './components/aboutUs.vue';
	import sunningSheet from './components/sunningSheet.vue';
	import news from './components/news.vue';
	import bottom from '@/components/bottom/index.vue';
	import appletBottom from '@/components/bottom/appletIndex.vue';
	import axios from 'axios';
	import {
		changeKeywords,
		changeDescription,
		changeTitle
	} from '@/utils/brower.js'
	export default {
		name: "home",
		components: {
			top,
			carousel,
			catalogue,
			specialSale,
			service,
			accordion,
			aboutUs,
			sunningSheet,
			news,
			bottom,
			right,
			appletBottom
		},
		data() {
			return {
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
			};
		},
		created() {
			// console.log("$store",this.$store.state)
			changeTitle('深圳市格物思信息科技有限公司 官网-成为中国最值得信赖的IC元器件、PCB、SMT科技贸易服务商')
			changeKeywords('深圳格物思、元器件、PCB、SMT等一站式PCBA服务')
			changeDescription(
				'深圳市格物思信息科技有限公司成立于2009年，目前在国内设立多家分公司（深圳芯航国际电子有限公司，北京格物思电子有限公司，武汉格物思信息科技有限公司等），并在香港、深圳、德国、美国等地设立仓储物流中心；全国设有两大生产基地：安徽广德产业基地和浙江诸暨视觉智能产业园。目前已为欧洲、亚洲的客户提供涵盖元器件、PCB、SMT等一站式PCBA服务。利用互联网、物联网、云技术对电子制造业细分行业全价值链进行深度的改造，采用分析行业共同需求、集中采购、拉式补货的备货方式，帮助工厂 式，帮助工厂客户按客户需要进行生产，实现真正意义上的VMI模式。'
				)
			// changeTitle('深圳格物思电子有限公司 官网-成为中国最值得信赖的IC元器件、PCB、SMT科技贸易服务商')
			// changeKeywords('深圳格物思、元器件、PCB、SMT等一站式PCBA服务')
			// changeDescription('深圳格物思电子有限公司成立于2009年，目前在国内设立多家分公司（深圳格物思电子有限公司，北京格物思电子有限公司，武汉格物思信息科技有限公司等），并在香港、深圳、德国、美国等地设立仓储物流中心；全国设有两大生产基地：安徽广德产业基地和浙江诸暨视觉智能产业园。目前已为欧洲、亚洲的客户提供涵盖元器件、PCB、SMT等一站式PCBA服务。利用互联网、物联网、云技术对电子制造业细分行业全价值链进行深度的改造，采用分析行业共同需求、集中采购、拉式补货的备货方式，帮助工厂 式，帮助工厂客户按客户需要进行生产，实现真正意义上的VMI模式。')
			// console.log('本地语言',navigator.language)
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
			// axios.get('https://www.tianyancha.com/search').then(response => {
			// 	// this.ipaddress = response.data.split("您的IP地址是：")[1].split("</title>")[0]
			// 	console.log("ipaddress",response)
			// }).catch(error => {
			//     console.log(error);
			// });
			// axios({
			// 		method: "get",
			// 		url: "http://localhost:8080/global.html?k=1N4007&hot-key=LM358", //请求的链接地址
			// 		headers: {
			// 			"Access-Control-Allow-Origin": "*",
			// 			"Content-Type": "text/plain",
			// 			'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
			// 			'Access-Control-Allow-Headers': 'Content-Type',
			// 		},
			// 		params: {},
			// 	})
			// 	.then((resp) => {
			// 		//如果成功将在这里进行后续的逻辑操作
			// 		console.log(resp)
			// 	})
			// 	.catch((err) => {
			// 		//如果请求失败将在这里进行后续的逻辑操作

			// 	});
			// console.log(this.$permission.isTime('2023-08-14T18:58:42',true))
		},

		watch: {
			//双向绑定语言切换
			'$i18n.locale'(val) {

			}
		},
		computed: {

		},
		mounted() {

		},

		updated() {},

		beforeDestroy() {

		},

		beforeCreate() {

		},
		methods: {

		},
	};
</script>

<style lang="less" scoped>
	body {}

	@font-face {
		font-family: 'BigJohn';
		src: url('../../assets/big-john-woff2-2.ttf') format('truetype');
	}

	@font-face {
		font-family: 'zhenyan';
		src: url('../../assets/RuiZiZhenYanTiMianFeiShangYong-2.ttf') format('truetype');
	}
</style>