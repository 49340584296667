<template>
	<div class="service">
		<div class="sv_div">
			<div class="sv_di_title">{{$t('home.srvice.title')}}</div>
			<div class="sv_di_text">{{$t('home.srvice.text')}}</div>
			<div class="sv_di_contentList">
				<div class="sv_di_cl_div" v-for="(item,index) in $t('home.srvice.list')" :key="index">
					<div class="sv_di_cl_div_imgDiv">
						<el-image class="image" :src="item.image" :fit="'cover'"></el-image>
					</div>
					<div class="sv_di_cl_div_title">{{item.title}}</div>
					<div class="sv_di_cl_div_text">
						<div style="color: #777;" v-for="(item2,index2) in item.content" :key="index2">{{item2}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return{
				list:[{
					title:'供应互联',
					image:require('@/assets/img/interconnection.png'),
					content:['A级板材 , 原厂直供','战略合作 , 准备充沛','高精设备 , 产能稳定'],
				},
				{
					title:'智能配单',
					image:require('@/assets/img/matchingOrder.png'),
					content:['智能计价系统','报价透明','1v1疑问解答'],
				},
				{
					title:'智能工程',
					image:require('@/assets/img/engineering.png'),
					content:['强大工程处理力','提速70%','下达产线快至30分钟'],
				},
				{
					title:'极速送达',
					image:require('@/assets/img/logistics.png'),
					content:['两大生产基地','六大服务网点','完善物流体系'],
				},
				{
					title:'及时响应',
					image:require('@/assets/img/respond.png'),
					content:['售后快速响应','无推诿','99.9%好评率'],
				}]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.service{
			padding: 0 200px 40px 200px;
			width: 100%;
			
			.sv_div{
				width: 100%;
				
				.sv_di_title{
					font-size: 32px;
				}
				
				.sv_di_text{
					font-size: 16px;
					padding-top: 10px;
				}
				
				.sv_di_contentList{
					width: 100%;
					display: flex;
					justify-content: center;
					
					.sv_di_cl_div{
						width: calc(100% / 5);
						padding: 50px 20px;
						background: #fff;
						margin: 20px 10px;
						text-align: center;
						
						.sv_di_cl_div_imgDiv{
							display: flex;
							justify-content: center;
							
							.image{
								width: 40px;
								height: 40px;
							}
						}
						
						.sv_di_cl_div_title{
							font-size: 20px;
							padding-top: 20px;
						}
						
						.sv_di_cl_div_text{
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.service{
			padding: 0 200px 40px 200px;
			width: 100%;
			
			.sv_div{
				width: 100%;
				
				.sv_di_title{
					font-size: 32px;
				}
				
				.sv_di_text{
					font-size: 16px;
					padding-top: 10px;
				}
				
				.sv_di_contentList{
					width: 100%;
					display: flex;
					justify-content: center;
					
					.sv_di_cl_div{
						width: calc(100% / 5);
						padding: 50px 20px;
						background: #fff;
						margin: 20px 10px;
						text-align: center;
						
						.sv_di_cl_div_imgDiv{
							display: flex;
							justify-content: center;
							
							.image{
								width: 40px;
								height: 40px;
							}
						}
						
						.sv_di_cl_div_title{
							font-size: 20px;
							padding-top: 20px;
						}
						
						.sv_di_cl_div_text{
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.service{
			padding: 0 130px 40px 130px;
			width: 100%;
			
			.sv_div{
				width: 100%;
				
				.sv_di_title{
					font-size: 32px;
				}
				
				.sv_di_text{
					font-size: 16px;
					padding-top: 10px;
				}
				
				.sv_di_contentList{
					width: 100%;
					display: flex;
					justify-content: center;
					
					.sv_di_cl_div{
						width: calc(100% / 5);
						padding: 50px 20px;
						background: #fff;
						margin: 20px 10px;
						text-align: center;
						
						.sv_di_cl_div_imgDiv{
							display: flex;
							justify-content: center;
							
							.image{
								width: 40px;
								height: 40px;
							}
						}
						
						.sv_di_cl_div_title{
							font-size: 20px;
							padding-top: 20px;
						}
						
						.sv_di_cl_div_text{
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.service{
			padding: 0 30px 40px 30px;
			width: 100%;
			
			.sv_div{
				width: 100%;
				
				.sv_di_title{
					font-size: 32px;
				}
				
				.sv_di_text{
					font-size: 16px;
					padding-top: 10px;
				}
				
				.sv_di_contentList{
					width: 100%;
					display: flex;
					justify-content: center;
					
					.sv_di_cl_div{
						width: calc(100% / 5);
						padding: 50px 20px;
						background: #fff;
						margin: 20px 10px;
						text-align: center;
						
						.sv_di_cl_div_imgDiv{
							display: flex;
							justify-content: center;
							
							.image{
								width: 40px;
								height: 40px;
							}
						}
						
						.sv_di_cl_div_title{
							font-size: 20px;
							padding-top: 20px;
						}
						
						.sv_di_cl_div_text{
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.service{
			padding: 0 30px 40px 30px;
			width: 100%;
			
			.sv_div{
				width: 100%;
				text-align: center;
				
				.sv_di_title{
					font-size: 20px;
				}
				
				.sv_di_text{
					font-size: 16px;
					padding-top: 10px;
				}
				
				.sv_di_contentList{
					width: 100%;
					display: flex;
					justify-content: center;
					flex-wrap:wrap;
					
					.sv_di_cl_div{
						width: calc(100% / 4);
						padding: 50px 20px;
						background: #fff;
						margin: 20px 10px;
						text-align: center;
						
						.sv_di_cl_div_imgDiv{
							display: flex;
							justify-content: center;
							
							.image{
								width: 40px;
								height: 40px;
							}
						}
						
						.sv_di_cl_div_title{
							font-size: 20px;
							padding-top: 20px;
						}
						
						.sv_di_cl_div_text{
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.service{
			padding: 0 30px 40px 30px;
			width: 100%;
			
			.sv_div{
				width: 100%;
				text-align: center;
				
				.sv_di_title{
					font-size: 20px;
				}
				
				.sv_di_text{
					font-size: 16px;
					padding-top: 10px;
				}
				
				.sv_di_contentList{
					width: 100%;
					display: flex;
					justify-content: center;
					flex-wrap:wrap;
					
					.sv_di_cl_div{
						width: calc(100% / 2);
						padding: 50px 20px;
						background: #fff;
						margin: 20px 10px;
						text-align: center;
						
						.sv_di_cl_div_imgDiv{
							display: flex;
							justify-content: center;
							
							.image{
								width: 40px;
								height: 40px;
							}
						}
						
						.sv_di_cl_div_title{
							font-size: 20px;
							padding-top: 20px;
						}
						
						.sv_di_cl_div_text{
							padding-top: 20px;
						}
					}
				}
			}
		}
	}
	
</style>