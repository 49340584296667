<template>
	<div>
		<div v-if="windowWidth>=1200">
			<div class="sunningSheetTitle">
				<div class="sst_title">{{$t('home.suningSheet.title')}}</div>
				<div class="sst_textDiv">
					<div class="sst_td_text">
						{{$t('home.suningSheet.label')}}</div>
				</div>
			
				<div class="sst_text">{{$t('home.suningSheet.text')}}</div>
			</div>
			<div class="sunningSheetContent" :style="'background-image:' + `url(${require('@/assets/img/customer-reviews@Bg.jpg')})`">
				<el-carousel height="600px">
					<el-carousel-item v-for="(item,index) in $t('home.suningSheet.list')" :key="index">
						<div class="ssc_carouselDiv">
							<div class="ssc_cd_dataListDiv" v-for="(item2,index2) in item.data" :key="index2">
								<div>
									<el-image class="ssc_cd_dl_image" :src="item2.image" :fit="'cover'"></el-image>
								</div>
								<div class="ssc_cd_dl_name">
									<el-image class="ssc_cd_dl_na_image" :src="require('@/assets/img/head.png')" :fit="'cover'"></el-image>
									<div class="ssc_cd_dl_na_text">{{item2.name}}</div>
								</div>
								<div class="ssc_cd_dl_content">
									<div style="display: flex;">
										<div class="ssc_cd_dl_ct_label" v-for="(item3,index3) in item2.label" :key="index3">
											{{item3}}
										</div>
									</div>
									<div class="ssc_cd_dl_ct_title">{{item2.title}}</div>
									<div class="ssc_cd_dl_ct_content">
										{{item2.content}}
									</div>
									<div style="padding-top: 10px;">
										<el-rate
										  v-model="item2.value"
										  disabled
										  show-score
										  :colors="['#d1102d','#d1102d','#d1102d']"
										  text-color="#fff"
										  score-template="{value}">
										</el-rate>
										<div style="font-size: 16px;color: #888;padding-top: 20px;">{{item2.time}}</div>
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		
		<div v-if="windowWidth<=1199">
			<div class="sunningSheetTitle2">
				<div class="sst_title">{{$t('home.suningSheet.title')}}</div>
				<div class="sst_textDiv">
					<div class="sst_td_text">
						{{$t('home.suningSheet.label')}}</div>
				</div>
			
				<div class="sst_text">{{$t('home.suningSheet.text')}}</div>
			</div>
			<div class="sunningSheetContent2" :style="'background-image:' + `url(${require('@/assets/img/customer-reviews@Bg.jpg')})`">
				<el-carousel height="600px">
					<el-carousel-item v-for="(item,index) in $t('home.suningSheet.list2')" :key="index">
						<div class="ssc_carouselDiv">
							<div class="ssc_cd_dataListDiv" v-for="(item2,index2) in item.data" :key="index2">
								<div>
									<el-image class="ssc_cd_dl_image" :src="item2.image" :fit="'cover'"></el-image>
								</div>
								<div class="ssc_cd_dl_name">
									<el-image class="ssc_cd_dl_na_image" :src="require('@/assets/img/head.png')" :fit="'cover'"></el-image>
									<div class="ssc_cd_dl_na_text">{{item2.name}}</div>
								</div>
								<div class="ssc_cd_dl_content">
									<div style="display: flex;">
										<div class="ssc_cd_dl_ct_label" v-for="(item3,index3) in item2.label" :key="index3">
											{{item3}}
										</div>
									</div>
									<div class="ssc_cd_dl_ct_title">{{item2.title}}</div>
									<div class="ssc_cd_dl_ct_content">
										{{item2.content}}
									</div>
									<div style="padding-top: 10px;">
										<el-rate
										  v-model="item2.value"
										  disabled
										  show-score
										  :colors="['#d1102d','#d1102d','#d1102d']"
										  text-color="#fff"
										  score-template="{value}">
										</el-rate>
										<div style="font-size: 16px;color: #888;padding-top: 20px;">{{item2.time}}</div>
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list: [{
						data: [{
								name: '李先生/女士',
								value: 5,
								title: '10*10*cm ,1.6mm,2层,10片',
								label: ['PCB', '24H'],
								content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在捷多邦打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
								time: '2023-03-29',
								image:require('@/assets/img/sunning.jpg')
							},
							// {
							// 	name: '王先生/女士',
							// 	value: 5,
							// 	title: '10*10*cm ,1.6mm,2层,10片',
							// 	label: ['PCB', '24H'],
							// 	content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在捷多邦打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
							// 	time: '2023-03-29',
							// 	image:require('@/assets/img/sunning.jpg')
							// },
							// {
							// 	name: '哈先生/女士',
							// 	value: 5,
							// 	title: '10*10*cm ,1.6mm,2层,10片',
							// 	label: ['PCB', '24H'],
							// 	content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在捷多邦打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
							// 	time: '2023-03-29',
							// 	image:require('@/assets/img/sunning.jpg')
							// },
							// {
							// 	name: '蔡先生/女士',
							// 	value: 5,
							// 	title: '10*10*cm ,1.6mm,2层,10片',
							// 	label: ['PCB', '24H'],
							// 	content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在捷多邦打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
							// 	time: '2023-03-29',
							// 	image:require('@/assets/img/sunning.jpg')
							// },
						]

					},
					// {

					// 	data: [{
					// 			name: '李先生/女士',
					// 			value: 5,
					// 			title: '10*10*cm ,1.6mm,2层,10片',
					// 			label: ['PCB', '24H'],
					// 			content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在捷多邦打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
					// 			time: '2023-03-29',
					// 			image:require('@/assets/img/sunning.jpg')
					// 		},
					// 		{
					// 			name: '王先生/女士',
					// 			value: 5,
					// 			title: '10*10*cm ,1.6mm,2层,10片',
					// 			label: ['PCB', '24H'],
					// 			content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在捷多邦打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
					// 			time: '2023-03-29',
					// 			image:require('@/assets/img/sunning.jpg')
					// 		},
					// 		{
					// 			name: '哈先生/女士',
					// 			value: 5,
					// 			title: '10*10*cm ,1.6mm,2层,10片',
					// 			label: ['PCB', '24H'],
					// 			content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在捷多邦打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
					// 			time: '2023-03-29',
					// 			image:require('@/assets/img/sunning.jpg')
					// 		},
					// 		{
					// 			name: '蔡先生/女士',
					// 			value: 5,
					// 			title: '10*10*cm ,1.6mm,2层,10片',
					// 			label: ['PCB', '24H'],
					// 			content: '板子质量不错，孔位标准，阻焊层均匀，板材质量也比较好，值得推荐。在捷多邦打过好几次工业级的大板子，从没出过问题，值得推荐， 服务态度好 ，专业技能强 。',
					// 			time: '2023-03-29',
					// 			image:require('@/assets/img/sunning.jpg')
					// 		},
					// 	]


					// }
				]
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.sunningSheetTitle{
			padding: 40px 200px;
			position: relative;
			display: flex;
			border-top: 1px solid #efefef;
			background: #fff;
			align-items: center;
			
			.sst_title{
				font-size: 32px;
			}
			
			.sst_textDiv{
				margin-left: 20px;
				
				.sst_td_text{
					color: #fff;
					padding: 6px 20px;
					border-radius: 40px;
					background: #d1102d;
					font-size: 12px;
				}
			}
			
			.sst_text{
				font-size: 16px;
				color: #888;
				padding-left: 20px;
			}
		}
		
		.sunningSheetContent{
			padding: 40px 200px;
			
			.ssc_carouselDiv{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				
				.ssc_cd_dataListDiv{
					width: 25%;
					background: #fff;
					margin: 10px;
					
					.ssc_cd_dl_image{
						width: 100%;
						height: 200px;
					}
					
					.ssc_cd_dl_name{
						padding: 10px 20px;
						display: flex;
						align-items: center;
						border-bottom: 1px solid #efefef;
						
						.ssc_cd_dl_na_image{
							width: 40px;
							height: 40px;
						}
						
						.ssc_cd_dl_na_text{
							font-size: 16px;
							padding-left: 10px;
						}
					}
					
					.ssc_cd_dl_content{
						padding: 20px;
						background: #fff;
						
						.ssc_cd_dl_ct_label{
							padding: 2px 3px;
							margin-right: 10px;
							background: #d1102d;
							border-radius: 5px;
							font-size: 10px;
							color: #fff;
						}
						
						.ssc_cd_dl_ct_title{
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							padding-top: 10px;
							color: #888;
						}
						
						.ssc_cd_dl_ct_content{
							padding-top: 20px;
							line-height: 35px;
							height: 130px;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.sunningSheetTitle{
			padding: 40px 200px;
			position: relative;
			display: flex;
			border-top: 1px solid #efefef;
			background: #fff;
			align-items: center;
			
			.sst_title{
				font-size: 32px;
			}
			
			.sst_textDiv{
				margin-left: 20px;
				
				.sst_td_text{
					color: #fff;
					padding: 6px 20px;
					border-radius: 40px;
					background: #d1102d;
					font-size: 12px;
				}
			}
			
			.sst_text{
				font-size: 16px;
				color: #888;
				padding-left: 20px;
			}
		}
		
		.sunningSheetContent{
			padding: 40px 200px;
			
			.ssc_carouselDiv{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				
				.ssc_cd_dataListDiv{
					width: 25%;
					background: #fff;
					margin: 10px;
					
					.ssc_cd_dl_image{
						width: 100%;
						height: 200px;
					}
					
					.ssc_cd_dl_name{
						padding: 10px 20px;
						display: flex;
						align-items: center;
						border-bottom: 1px solid #efefef;
						
						.ssc_cd_dl_na_image{
							width: 40px;
							height: 40px;
						}
						
						.ssc_cd_dl_na_text{
							font-size: 16px;
							padding-left: 10px;
						}
					}
					
					.ssc_cd_dl_content{
						padding: 20px;
						background: #fff;
						
						.ssc_cd_dl_ct_label{
							padding: 2px 3px;
							margin-right: 10px;
							background: #d1102d;
							border-radius: 5px;
							font-size: 10px;
							color: #fff;
						}
						
						.ssc_cd_dl_ct_title{
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							padding-top: 10px;
							color: #888;
						}
						
						.ssc_cd_dl_ct_content{
							padding-top: 20px;
							line-height: 35px;
							height: 130px;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.sunningSheetTitle{
			padding: 40px 140px;
			position: relative;
			display: flex;
			border-top: 1px solid #efefef;
			background: #fff;
			align-items: center;
			
			.sst_title{
				font-size: 32px;
			}
			
			.sst_textDiv{
				margin-left: 20px;
				
				.sst_td_text{
					color: #fff;
					padding: 6px 20px;
					border-radius: 40px;
					background: #d1102d;
					font-size: 12px;
				}
			}
			
			.sst_text{
				font-size: 16px;
				color: #888;
				padding-left: 20px;
			}
		}
		
		.sunningSheetContent{
			padding: 40px 140px;
			
			.ssc_carouselDiv{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				
				.ssc_cd_dataListDiv{
					width: 25%;
					background: #fff;
					margin: 10px;
					
					.ssc_cd_dl_image{
						width: 100%;
						height: 200px;
					}
					
					.ssc_cd_dl_name{
						padding: 10px 20px;
						display: flex;
						align-items: center;
						border-bottom: 1px solid #efefef;
						
						.ssc_cd_dl_na_image{
							width: 40px;
							height: 40px;
						}
						
						.ssc_cd_dl_na_text{
							font-size: 16px;
							padding-left: 10px;
						}
					}
					
					.ssc_cd_dl_content{
						padding: 20px;
						background: #fff;
						
						.ssc_cd_dl_ct_label{
							padding: 2px 3px;
							margin-right: 10px;
							background: #d1102d;
							border-radius: 5px;
							font-size: 10px;
							color: #fff;
						}
						
						.ssc_cd_dl_ct_title{
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							padding-top: 10px;
							color: #888;
						}
						
						.ssc_cd_dl_ct_content{
							padding-top: 20px;
							line-height: 35px;
							height: 130px;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.sunningSheetTitle{
			padding: 40px 30px;
			position: relative;
			display: flex;
			border-top: 1px solid #efefef;
			background: #fff;
			align-items: center;
			
			.sst_title{
				font-size: 32px;
			}
			
			.sst_textDiv{
				margin-left: 20px;
				
				.sst_td_text{
					color: #fff;
					padding: 6px 20px;
					border-radius: 40px;
					background: #d1102d;
					font-size: 12px;
				}
			}
			
			.sst_text{
				font-size: 16px;
				color: #888;
				padding-left: 20px;
			}
		}
		
		.sunningSheetContent{
			padding: 40px 30px;
			
			.ssc_carouselDiv{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				
				.ssc_cd_dataListDiv{
					width: 25%;
					background: #fff;
					margin: 10px;
					
					.ssc_cd_dl_image{
						width: 100%;
						height: 200px;
					}
					
					.ssc_cd_dl_name{
						padding: 10px 20px;
						display: flex;
						align-items: center;
						border-bottom: 1px solid #efefef;
						
						.ssc_cd_dl_na_image{
							width: 40px;
							height: 40px;
						}
						
						.ssc_cd_dl_na_text{
							font-size: 16px;
							padding-left: 10px;
						}
					}
					
					.ssc_cd_dl_content{
						padding: 20px;
						background: #fff;
						
						.ssc_cd_dl_ct_label{
							padding: 2px 3px;
							margin-right: 10px;
							background: #d1102d;
							border-radius: 5px;
							font-size: 10px;
							color: #fff;
						}
						
						.ssc_cd_dl_ct_title{
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							padding-top: 10px;
							color: #888;
						}
						
						.ssc_cd_dl_ct_content{
							padding-top: 20px;
							line-height: 35px;
							height: 130px;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.sunningSheetTitle2{
			padding: 40px 30px;
			position: relative;
			display: flex;
			border-top: 1px solid #efefef;
			background: #fff;
			align-items: center;
			
			.sst_title{
				font-size: 32px;
			}
			
			.sst_textDiv{
				margin-left: 20px;
				
				.sst_td_text{
					color: #fff;
					padding: 6px 20px;
					border-radius: 40px;
					background: #d1102d;
					font-size: 12px;
				}
			}
			
			.sst_text{
				font-size: 16px;
				color: #888;
				padding-left: 20px;
			}
		}
		
		.sunningSheetContent2{
			padding: 40px 30px;
			
			.ssc_carouselDiv{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				
				.ssc_cd_dataListDiv{
					width: 50%;
					background: #fff;
					margin: 10px;
					
					.ssc_cd_dl_image{
						width: 100%;
						height: 200px;
					}
					
					.ssc_cd_dl_name{
						padding: 10px 20px;
						display: flex;
						align-items: center;
						border-bottom: 1px solid #efefef;
						
						.ssc_cd_dl_na_image{
							width: 40px;
							height: 40px;
						}
						
						.ssc_cd_dl_na_text{
							font-size: 16px;
							padding-left: 10px;
						}
					}
					
					.ssc_cd_dl_content{
						padding: 20px;
						background: #fff;
						
						.ssc_cd_dl_ct_label{
							padding: 2px 3px;
							margin-right: 10px;
							background: #d1102d;
							border-radius: 5px;
							font-size: 10px;
							color: #fff;
						}
						
						.ssc_cd_dl_ct_title{
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							padding-top: 10px;
							color: #888;
						}
						
						.ssc_cd_dl_ct_content{
							padding-top: 20px;
							line-height: 35px;
							height: 130px;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.sunningSheetTitle2{
			padding: 40px 30px;
			position: relative;
			display: flex;
			border-top: 1px solid #efefef;
			background: #fff;
			align-items: center;
			
			.sst_title{
				font-size: 32px;
			}
			
			.sst_textDiv{
				margin-left: 20px;
				
				.sst_td_text{
					color: #fff;
					padding: 6px 20px;
					border-radius: 40px;
					background: #d1102d;
					font-size: 12px;
				}
			}
			
			.sst_text{
				font-size: 16px;
				color: #888;
				padding-left: 20px;
			}
		}
		
		.sunningSheetContent2{
			padding: 40px 30px;
			
			.ssc_carouselDiv{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				
				.ssc_cd_dataListDiv{
					width: 100%;
					background: #fff;
					margin: 10px;
					
					.ssc_cd_dl_image{
						width: 100%;
						height: 200px;
					}
					
					.ssc_cd_dl_name{
						padding: 10px 20px;
						display: flex;
						align-items: center;
						border-bottom: 1px solid #efefef;
						
						.ssc_cd_dl_na_image{
							width: 40px;
							height: 40px;
						}
						
						.ssc_cd_dl_na_text{
							font-size: 16px;
							padding-left: 10px;
						}
					}
					
					.ssc_cd_dl_content{
						padding: 20px;
						background: #fff;
						
						.ssc_cd_dl_ct_label{
							padding: 2px 3px;
							margin-right: 10px;
							background: #d1102d;
							border-radius: 5px;
							font-size: 10px;
							color: #fff;
						}
						
						.ssc_cd_dl_ct_title{
							width: 100%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							padding-top: 10px;
							color: #888;
						}
						
						.ssc_cd_dl_ct_content{
							padding-top: 20px;
							line-height: 35px;
							height: 130px;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
	
</style>
