<template>
	<div>
		<div class="news" v-if="windowWidth>=1200">
			<div class="news_titleDiv">
				<div class="new_td_title">{{$t('home.news.title')}}</div>
			
				<div class="new_td_text">{{$t('home.news.text')}}</div>
				
				<div class="new_td_buttonDiv">
					<div class="new_td_bd_text">{{$t('home.news.button')}} →</div>
				</div>
			</div>
			
			<div class="news_contentDiv">
				<div class="news_cd_div" v-for="(item,index) in $t('home.news.list')" :key="index">
					<div class="news_cd_div_time">{{item.time}}</div>
					<el-image class="news_cd_div_img" :src="item.image" :fit="'cover'"></el-image>
					<div class="news_cd_div_title">{{item.title}}</div>
					<div class="news_cd_div_content">
						{{item.content}}
					</div>
					<!-- <div style="position: relative;padding-top: 20px;">
						<span style="padding: 10px 20px;border-radius: 40px;border: 1px solid #888;color: #888;">查看更多 →</span>
					</div> -->
				</div>
			</div>
		</div>
		
		<div class="news2" v-if="windowWidth<=1199">
			<div class="news_titleDiv">
				<div class="new_td_title">{{$t('home.news.title')}}</div>
			
				<div class="new_td_text">{{$t('home.news.text')}}</div>
				
				<div class="new_td_buttonDiv">
					<div class="new_td_bd_text">{{$t('home.news.button')}} →</div>
				</div>
			</div>
			
			<div class="news_contentDiv">
				<div class="news_cd_div" v-for="(item,index) in $t('home.news.list')" :key="index">
					<div class="news_cd_div_time">{{item.time}}</div>
					<el-image class="news_cd_div_img" :src="item.image" :fit="'cover'"></el-image>
					<div class="news_cd_div_title">{{item.title}}</div>
					<div class="news_cd_div_content">
						{{item.content}}
					</div>
					<!-- <div style="position: relative;padding-top: 20px;">
						<span style="padding: 10px 20px;border-radius: 40px;border: 1px solid #888;color: #888;">查看更多 →</span>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				list:[{
					image:require('@/assets/img/newbg.png'),
					time:'2023.03.20',
					title:'2015中国（成都）电子展',
					content:'2015年7月16日-18日，芯航国际（XxinTek）参加了2015中国（成都）电子展，芯航国际展位设在成都世纪城新国际会展中心3号馆C247。芯航国际主要以英国光电厂牌PHOTE全线产品授权代理、芯商城（www.gchips.com）线上交易平台、海外原厂代理一手货源价格低货期短、军品优势'
				},
				// {
				// 	image:require('@/assets/img/newbg.jpg'),
				// 	time:'2023.03.20',
				// 	title:'2015中国（成都）电子展',
				// 	content:'2015年7月16日-18日，芯航国际（XxinTek）参加了2015中国（成都）电子展，芯航国际展位设在成都世纪城新国际会展中心3号馆C247。芯航国际主要以英国光电厂牌PHOTE全线产品授权代理、芯商城（www.gchips.com）线上交易平台、海外原厂代理一手货源价格低货期短、军品优势'
				// },
				// {
				// 	image:require('@/assets/img/newbg.jpg'),
				// 	time:'2023.03.20',
				// 	title:'2015中国（成都）电子展',
				// 	content:'2015年7月16日-18日，芯航国际（XxinTek）参加了2015中国（成都）电子展，芯航国际展位设在成都世纪城新国际会展中心3号馆C247。芯航国际主要以英国光电厂牌PHOTE全线产品授权代理、芯商城（www.gchips.com）线上交易平台、海外原厂代理一手货源价格低货期短、军品优势'
				// },
				// {
				// 	image:require('@/assets/img/newbg.jpg'),
				// 	time:'2023.03.20',
				// 	title:'2015中国（成都）电子展',
				// 	content:'2015年7月16日-18日，芯航国际（XxinTek）参加了2015中国（成都）电子展，芯航国际展位设在成都世纪城新国际会展中心3号馆C247。芯航国际主要以英国光电厂牌PHOTE全线产品授权代理、芯商城（www.gchips.com）线上交易平台、海外原厂代理一手货源价格低货期短、军品优势'
				// },
				]
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.news{
			padding: 40px 200px;
			background: #fff;
			position: relative;
			
			.news_titleDiv{
				position: relative;
				display: flex;
				background: #fff;
				align-items: center;
				
				.new_td_title{
					font-size: 32px;
				}
				
				.new_td_text{
					font-size: 16px;
					color: #888;
					padding-left: 20px;
				}
				
				.new_td_buttonDiv{
					position: absolute;
					right: 0;
					
					.new_td_bd_text{
						padding: 10px 20px;
						border-radius: 40px;
						border: 1px solid #888;
						color: #888;
					}
				}
			}
			
			.news_contentDiv{
				display: flex;
				
				.news_cd_div{
					width: 25%;
					padding: 20px 0;
					text-align: left;
					padding-right: 20px;
					
					.news_cd_div_time{
						color: #888;
						font-size: 16px;
					}
					
					.news_cd_div_img{
						width: 100%;
						height: 200px;
						margin-top: 20px;
					}
					
					.news_cd_div_title{
						padding-top: 10px;
					}
					
					.news_cd_div_content{
						padding-top: 10px;
						line-height: 35px;
						height: 110px;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
						color: #888;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.news{
			padding: 40px 200px;
			background: #fff;
			position: relative;
			
			.news_titleDiv{
				position: relative;
				display: flex;
				background: #fff;
				align-items: center;
				
				.new_td_title{
					font-size: 32px;
				}
				
				.new_td_text{
					font-size: 16px;
					color: #888;
					padding-left: 20px;
				}
				
				.new_td_buttonDiv{
					position: absolute;
					right: 0;
					
					.new_td_bd_text{
						padding: 10px 20px;
						border-radius: 40px;
						border: 1px solid #888;
						color: #888;
					}
				}
			}
			
			.news_contentDiv{
				display: flex;
				
				.news_cd_div{
					width: 25%;
					padding: 20px 0;
					text-align: left;
					padding-right: 20px;
					
					.news_cd_div_time{
						color: #888;
						font-size: 16px;
					}
					
					.news_cd_div_img{
						width: 100%;
						height: 200px;
						margin-top: 20px;
					}
					
					.news_cd_div_title{
						padding-top: 10px;
					}
					
					.news_cd_div_content{
						padding-top: 10px;
						line-height: 35px;
						height: 110px;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
						color: #888;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.news{
			padding: 40px 140px;
			background: #fff;
			position: relative;
			
			.news_titleDiv{
				position: relative;
				display: flex;
				background: #fff;
				align-items: center;
				
				.new_td_title{
					font-size: 32px;
				}
				
				.new_td_text{
					font-size: 16px;
					color: #888;
					padding-left: 20px;
				}
				
				.new_td_buttonDiv{
					position: absolute;
					right: 0;
					
					.new_td_bd_text{
						padding: 10px 20px;
						border-radius: 40px;
						border: 1px solid #888;
						color: #888;
					}
				}
			}
			
			.news_contentDiv{
				display: flex;
				
				.news_cd_div{
					width: 25%;
					padding: 20px 0;
					text-align: left;
					padding-right: 20px;
					
					.news_cd_div_time{
						color: #888;
						font-size: 16px;
					}
					
					.news_cd_div_img{
						width: 100%;
						height: 200px;
						margin-top: 20px;
					}
					
					.news_cd_div_title{
						padding-top: 10px;
					}
					
					.news_cd_div_content{
						padding-top: 10px;
						line-height: 35px;
						height: 110px;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
						color: #888;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.news{
			padding: 40px 30px;
			background: #fff;
			position: relative;
			
			.news_titleDiv{
				position: relative;
				display: flex;
				background: #fff;
				align-items: center;
				
				.new_td_title{
					font-size: 32px;
				}
				
				.new_td_text{
					font-size: 16px;
					color: #888;
					padding-left: 20px;
				}
				
				.new_td_buttonDiv{
					position: absolute;
					right: 0;
					
					.new_td_bd_text{
						padding: 10px 20px;
						border-radius: 40px;
						border: 1px solid #888;
						color: #888;
					}
				}
			}
			
			.news_contentDiv{
				display: flex;
				
				.news_cd_div{
					width: 25%;
					padding: 20px 0;
					text-align: left;
					padding-right: 20px;
					
					.news_cd_div_time{
						color: #888;
						font-size: 16px;
					}
					
					.news_cd_div_img{
						width: 100%;
						height: 200px;
						margin-top: 20px;
					}
					
					.news_cd_div_title{
						padding-top: 10px;
					}
					
					.news_cd_div_content{
						padding-top: 10px;
						line-height: 35px;
						height: 110px;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
						color: #888;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.news2{
			padding: 40px 30px;
			background: #fff;
			position: relative;
			
			.news_titleDiv{
				position: relative;
				background: #fff;
				text-align: center;
				
				.new_td_title{
					font-size: 21px;
				}
				
				.new_td_text{
					padding-top: 20px;
					font-size: 16px;
					color: #888;
				}
				
				.new_td_buttonDiv{
					display: flex;
					padding-top: 20px;
					justify-content: center;	
					
					.new_td_bd_text{
						padding: 10px 20px;
						border-radius: 40px;
						border: 1px solid #888;
						color: #888;
					}
				}
			}
			
			.news_contentDiv{
				
				.news_cd_div{
					width: 100%;
					padding: 20px 0;
					text-align: left;
					padding-right: 20px;
					
					.news_cd_div_time{
						color: #888;
						font-size: 16px;
					}
					
					.news_cd_div_img{
						width: 100%;
						height: 200px;
						margin-top: 20px;
					}
					
					.news_cd_div_title{
						padding-top: 10px;
					}
					
					.news_cd_div_content{
						padding-top: 10px;
						line-height: 35px;
						height: 110px;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
						color: #888;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.news2{
			padding: 40px 30px;
			background: #fff;
			position: relative;
			
			.news_titleDiv{
				position: relative;
				display: flex;
				flex-wrap: wrap;
				background: #fff;
				align-items: center;
				
				.new_td_title{
					font-size: 21px;
				}
				
				.new_td_text{
					font-size: 12px;
					color: #888;
					padding-left: 20px;
				}
				
				.new_td_buttonDiv{
					right: 0;
					
					.new_td_bd_text{
						padding: 5px 10px;
						border-radius: 40px;
						border: 1px solid #888;
						color: #888;
						margin-top: 10px;
						font-size: 12px;
					}
				}
			}
			
			.news_contentDiv{
				
				.news_cd_div{
					width: 100%;
					padding: 20px 0;
					text-align: left;
					padding-right: 20px;
					
					.news_cd_div_time{
						color: #888;
						font-size: 16px;
					}
					
					.news_cd_div_img{
						width: 100%;
						height: 200px;
						margin-top: 20px;
					}
					
					.news_cd_div_title{
						padding-top: 10px;
					}
					
					.news_cd_div_content{
						padding-top: 10px;
						line-height: 35px;
						height: 110px;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
						overflow: hidden;
						color: #888;
					}
				}
			}
		}
	}
</style>