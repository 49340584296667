<template>
	<div>
		<div class="block">
			<div class="carouselDiv" v-if="windowWidth>=1100">
				<div>
					<div class="cd_tabs">
						<div class="cd_tabs_name" :style="selectIndex == index && index == 0 ? 'background: #fff;border-top-left-radius: 6px;' : selectIndex == index && index == 3 ? 'background: #fff;border-top-right-radius: 6px;' : selectIndex == index ? 'background: #fff;' : '' "
							v-for="(item,index) in $t('home.carousel.selectTabs')" :key="index" @click="selectClick(index)">
							{{item.name}}
						</div>
					</div>
					<div class="cd_content" @click="jump">
						<el-image style="height: 100%;width: 100%;" :src="elImg" :fit="'cover'"></el-image>
						<div class="cd_ct_button">
							<div class="cd_ct_text">{{$t('home.carousel.button')}}</div>
						</div>
					</div>
					
					<!-- <div class="cd_content" v-if="selectIndex==1">
						<div class="cd_ct_pcb">
							<div>
								<div class="cd_ct_pcb_title1">板子尺寸：</div>
								<div style="display: flex;">
									<input class="cd_ct_pcb_input3" type="text" v-model="boardLength" placeholder="长/y" />
								</div>
							</div>
							
							<div>
								<div class="cd_ct_pcb_title2">层数：</div>
								<div style="display: flex;margin-left: 20px;">
									<input class="cd_ct_pcb_input3" type="text" v-model="boardLength" placeholder="长/y" />
								</div>
							</div>
						</div>
						<div class="cd_ct_pcb" style="padding-top: 20px;">
							<div>
								<div class="cd_ct_pcb_title1">工艺要求：</div>
								<single-choice :dataIndex="0" :dataList="technologyList" @select=""></single-choice>
							</div>
							
							<div>
								<div class="cd_ct_pcb_title2">代购元件：</div>
								<single-choice style="margin-left: 20px;" :dataIndex="0" :dataList="purchaseBehalf" @select=""></single-choice>
							</div>
						</div>
						
						<div class="cd_ct_button">
							<div class="cd_ct_text" @click="jump">立即报价</div>
						</div>
					</div>
					
					<div class="cd_content" v-if="selectIndex==2">
						<div class="cd_ct_pcb">
							<div>
								<div class="cd_ct_pcb_title1">开钢网数量：</div>
								<div style="display: flex;">
									<input class="cd_ct_pcb_input3" type="text" v-model="boardLength" placeholder="长/y" />
								</div>
							</div>
						</div>
						<div class="cd_ct_pcb" style="padding-top: 20px;">
							<div>
								<div class="cd_ct_pcb_title1">MARK需求：</div>
								<single-choice :dataIndex="0" :dataList="markList" @select=""></single-choice>
							</div>
						</div>
						
						<div class="cd_ct_pcb" style="padding-top: 20px;">
							<div>
								<div class="cd_ct_pcb_title1">钢网用途：</div>
								<single-choice :dataIndex="0" :dataList="markList" @select=""></single-choice>
							</div>
						</div>
						
						<div class="cd_ct_button">
							<div class="cd_ct_text" @click="jump">立即报价</div>
						</div>
					</div>
					
					<div class="cd_content" v-if="selectIndex==3">
						<div class="cd_ct_pcb" style="justify-content: center;width: 100%;">
							<div>
								<div class="cd_ct_pcb_title1">上传一个BOM配单：</div>
								<div>
									<file-upload style="width: 600px;"></file-upload>
								</div>
							</div>
						</div>
						
						<div class="cd_ct_button">
							<div class="cd_ct_text" @click="jump">立即报价</div>
						</div>
					</div> -->
				</div>
			</div>
			<a-carousel v-if="windowWidth>=768">
				<div v-for="(item,index) in imageList" :key="index" :style="windowWidth<=1199 ? 'margin-top:70px;' : ''">
					<el-image style="height: 100%;width: 100%;" :src="item.image" :fit="'cover'"></el-image>
				</div>
			</a-carousel>
			
			<a-carousel v-if="windowWidth<=767">
				<div v-for="(item,index) in imageList2" :key="index" style="margin-top:70px;">
					<el-image style="height: 100%;width: 100%;" :src="item.image" :fit="'cover'"></el-image>
				</div>
			</a-carousel>
		</div>
	</div>
</template>

<script>
	import {
		dataInJs
	} from '/src/business/valuation/components/data.js';
	import {dataInfo} from '@/business/valuation/components//dataHandling.js';
	import { calcPcbPriceReturn } from '/src/business/valuation/components/saveOrder.js';
	import singleChoice from '@/components/singleChoice/index.vue';
	import FileUpload from '@/components/FileUpload/index.vue';
	export default {
		components:{
			singleChoice,
			FileUpload
		},
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				boardLength:'',//板子长度
				boardWidth:'',//板子宽度
				boardNumber:'',//板子数量
				boardArea:'',//板子面积
				boardNumberPliesIndex:0,//板子层数
				boardThicknessIndex:0,//板子厚度下标
				BoardThickness:1,//板子厚度选项位置
				dataList:dataInJs(),
				elImg:process.env.VUE_APP_OSS_URL + '/home/homeCarousl.jpg',
				imageList: [{
						image: process.env.VUE_APP_OSS_URL + '/home/bg1.jpg?' + new Date(),
				}],
				imageList2: [{
						image: process.env.VUE_APP_OSS_URL + '/home/bg2.jpg?' + new Date(),
				}],
				selectTabs: [{
						id: 1,
						name: 'PCB计价'
					},
					{
						id: 2,
						name: 'SMT打样'
					},
					{
						id: 3,
						name: '钢网下单'
					},
					{
						id: 4,
						name: 'BOM配单'
					}
				],
				technologyList:[{
					name:'有铅'
				},
				{
					name:'无铅'
				}],
				purchaseBehalf:[{
					name:'是'
				},
				{
					name:'否'
				}],
				markList:[{
					name:'不需要'
				},
				{
					name:'半刻'
				},
				{
					name:'通孔'
				}],
				steelMeshUsage:[{
					name:"锡膏网"
				},
				{
					name:"红胶网"
				}],
				numberPliesType:false,//是否打开层数弹框
				quantityType:false,//是否打开数量弹框
				thicknessType:false,//是否打开厚度弹框
				numberPliesNameIndex:null,//层数下标
				thicknessIndex:null,//厚度下标
				selectIndex: 0, //selectTabs下标
				radio: 1,
			}
		},

		created() {
			this.boardLength = this.$store.state.selectForm.boardLength;
			this.boardWidth = this.$store.state.selectForm.boardWidth;
			this.boardNumberPliesIndex = this.$store.state.selectForm.boardNumberPliesIndex - 1;
			this.boardThicknessIndex = this.$store.state.selectForm.boardThicknessIndex;
			this.radio = this.$store.state.selectForm.boardNumber;
			this.dataList=dataInfo(this.boardNumberPliesIndex);
		},
		
		mounted() {
			
		},

		watch: {
			boardLength(val){
				// console.log(val)
				this.$store.dispatch("setBoardLength", {
				    boardLength: val,
				});
				calcPcbPriceReturn()
			},
			boardWidth(val){
				// console.log(val)
				this.$store.dispatch("setBoardWidth", {
				    boardWidth: val,
				});
				calcPcbPriceReturn()
			},
			radio(val){
				this.$store.dispatch("setBoardNumber",{
					boardNumber:val
				})
			},
			
			windowWidth(val){
				// console.log(val)
			}
		},

		computed: {

		},

		methods: {
			//--------------------点击选择tabs-------------------------
			selectClick: function(index) {
				this.selectIndex = index;
				
					switch (index) {
						case 0:
							this.$router.push({
								path: '/valuation',
							})
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 0,
							});
							break;
						case 1:
							this.$router.push({
								path: '/valuation',
							})
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 2,
							});
							break;
						case 2:
							this.$router.push({
								path: '/valuation',
							})
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 3,
							});
							break;
						case 3:
							this.$router.push({
								path: '/valuation',
							})
							this.$store.dispatch("setTopTabsIndex", {
							    topTabsIndex: 4,
							});
							break;
						
						default:
							break;
					}
				
			},
			
			//-------------是否打开层数弹框------------------------------
			numberPliesMoserover:function(){
				this.numberPliesType=true;
			},
			
			//---------------关闭层数弹框-----------------------------
			numberPliesMouseleave:function(){
				this.numberPliesType=false;
			},
			
			//-------------是否打开数量弹框------------------------------
			quantityMoserover:function(){
				this.quantityType=true;
			},
			
			//---------------关闭数量弹框-----------------------------
			quantityMouseleave:function(){
				this.quantityType=false;
			},
			
			//-------------是否打开厚度弹框------------------------------
			thicknessMoserover:function(){
				this.thicknessType=true;
			},
			
			//---------------关闭厚度弹框-----------------------------
			thicknessMouseleave:function(){
				this.thicknessType=false;
			},
			
			//-------------是否移入层数弹框------------------------------
			numberPliesNameMoserover:function(index){
				this.numberPliesNameIndex=index;
			},
			
			//---------------移出层数弹框-----------------------------
			numberPliesNameMouseleave:function(){
				this.numberPliesNameIndex=null;
			},
			
			//-------------是否移入层数弹框------------------------------
			boardThicknessMoserover:function(index){
				this.thicknessIndex=index;
			},
			
			//---------------移出层数弹框-----------------------------
			boardThicknessMouseleave:function(){
				this.thicknessIndex=null;
			},
			
			//------------------选择层数--------------------------
			numberPliesNameClick:function(index){
				this.boardNumberPliesIndex=index;
				this.$store.dispatch("setBoardNumberPliesIndex", {
				    boardNumberPliesIndex: index + 1,
				});
				this.dataList=dataInfo(index);
				this.$forceUpdate();
				// console.log(this.dataList.boardThickness)
			},
			
			//------------------厚度下标-------------------------
			boardThicknessClick:function(index){
				this.boardThicknessIndex=index;
				this.$store.dispatch("setBoardThicknessIndex",{
					boardThicknessIndex:index
				})
				for(var i = 0; i < this.dataList.boardThickness.length; i ++){
					if(this.dataList.boardThickness[i].name == this.dataList.boardThickness[index].name){
						this.$store.dispatch("setBoardThickness",{
							BoardThickness:index+1
						})
					}
				}
			},
			
			jump:function(){
				this.$router.push({
					path: '/valuation',
				})
				this.$store.dispatch("setTopTabsIndex", {
				    topTabsIndex: 0,
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.block {
			position: relative;
			z-index: 2;
		}
		
		.small {
			// background: red;
			height: 100%;
		}
		
		input[type=text]:focus {
			outline: none;
			
		}
		
		.carouselDiv{
			position: absolute;
			left: 200px;
			height: 100%;
			display: flex;
			align-items: center;
			z-index: 6;
			
			.cd_tabs{
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				display: flex;
				background: #c9caca;
				
				.cd_tabs_name{
					padding: 16px 26px;
					color: #000;
					cursor: pointer;
				}
			}
			
			.cd_content{
				width: 100%;
				padding: 20px;
				background: #fff;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
				
				.cd_ct_pcb{
					display: flex;
					width: 100%;
					
					.cd_ct_pcb_title1{
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_title2{
						margin-left: 20px;
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_content1{
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct1_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct1_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct1_popover{
							position: absolute;
							top:32px;
							left: 0;
							width: 400px;
							border: 1px solid #d6d6d6;
							background: #fff;
							padding: 20px;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_content2{
						margin-left: 20px;
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct2_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct2_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct2_popover2{
							position: absolute;
							top:32px;
							left: 0;
							width: 100%;
							border: 1px solid #d6d6d6;
							background: #fff;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_input1{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_x{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input2{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_cm{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input3{
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
				}
				
				.cd_ct_button{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.cd_ct_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.block {
			position: relative;
			z-index: 2;
		}
		
		.small {
			// background: red;
			height: 100%;
		}
		
		input[type=text]:focus {
			outline: none;
			
		}
		
		.carouselDiv{
			position: absolute;
			left: 200px;
			display: flex;
			align-items: center;
			z-index: 6;
			
			.cd_tabs{
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				display: flex;
				background: #c9caca;
				
				.cd_tabs_name{
					padding: 16px 26px;
					color: #000;
					cursor: pointer;
				}
			}
			
			.cd_content{
				width: 100%;
				padding: 20px;
				background: #fff;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
				
				.cd_ct_pcb{
					display: flex;
					width: 100%;
					
					.cd_ct_pcb_title1{
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_title2{
						margin-left: 20px;
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_content1{
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct1_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct1_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct1_popover{
							position: absolute;
							top:32px;
							left: 0;
							width: 400px;
							border: 1px solid #d6d6d6;
							background: #fff;
							padding: 20px;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_content2{
						margin-left: 20px;
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct2_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct2_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct2_popover2{
							position: absolute;
							top:32px;
							left: 0;
							width: 100%;
							border: 1px solid #d6d6d6;
							background: #fff;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_input1{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_x{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input2{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_cm{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input3{
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
				}
				
				.cd_ct_button{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.cd_ct_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.block {
			position: relative;
			z-index: 2;
		}
		
		.small {
			// background: red;
			height: 100%;
		}
		
		input[type=text]:focus {
			outline: none;
			
		}
		
		.carouselDiv{
			position: absolute;
			left: 140px;
			height: 100%;
			display: flex;
			align-items: center;
			z-index: 6;
			
			.cd_tabs{
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				display: flex;
				background: #c9caca;
				
				.cd_tabs_name{
					padding: 16px 20px;
					color: #000;
					cursor: pointer;
				}
			}
			
			.cd_content{
				width: 400px;
				padding: 10px;
				background: #fff;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
				
				.cd_ct_pcb{
					display: flex;
					width: 100%;
					font-size: 10px;
					
					.cd_ct_pcb_title1{
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_title2{
						margin-left: 20px;
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_content1{
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct1_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct1_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct1_popover{
							position: absolute;
							top:32px;
							left: 0;
							width: 400px;
							border: 1px solid #d6d6d6;
							background: #fff;
							padding: 20px;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_content2{
						margin-left: 20px;
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct2_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct2_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct2_popover2{
							position: absolute;
							top:32px;
							left: 0;
							width: 100%;
							border: 1px solid #d6d6d6;
							background: #fff;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_input1{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_x{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input2{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_cm{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input3{
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
				}
				
				.cd_ct_button{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.cd_ct_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.block {
			position: relative;
			z-index: 2;
		}
		
		.small {
			// background: red;
			height: 100%;
		}
		
		input[type=text]:focus {
			outline: none;
			
		}
		
		.carouselDiv{
			position: absolute;
			left: 30px;
			height: 100%;
			display: flex;
			align-items: center;
			z-index: 6;
			
			.cd_tabs{
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				display: flex;
				background: #c9caca;
				
				.cd_tabs_name{
					padding: 16px 16px;
					color: #000;
					cursor: pointer;
				}
			}
			
			.cd_content{
				width: 360px;
				padding: 10px;
				background: #fff;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
				
				.cd_ct_pcb{
					display: flex;
					width: 100%;
					font-size: 6px;
					
					.cd_ct_pcb_title1{
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_title2{
						margin-left: 20px;
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_content1{
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct1_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct1_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct1_popover{
							position: absolute;
							top:32px;
							left: 0;
							width: 400px;
							border: 1px solid #d6d6d6;
							background: #fff;
							padding: 20px;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_content2{
						margin-left: 20px;
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct2_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct2_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct2_popover2{
							position: absolute;
							top:32px;
							left: 0;
							width: 100%;
							border: 1px solid #d6d6d6;
							background: #fff;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_input1{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_x{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input2{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_cm{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input3{
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
				}
				
				.cd_ct_button{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.cd_ct_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.block {
			position: relative;
			z-index: 2;
		}
		
		.small {
			// background: red;
			height: 100%;
		}
		
		input[type=text]:focus {
			outline: none;
			
		}
		
		.carouselDiv{
			position: absolute;
			left: 30px;
			height: 100%;
			display: flex;
			align-items: center;
			z-index: 6;
			top:30px;
			
			.cd_tabs{
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				display: flex;
				background: #c9caca;
				
				.cd_tabs_name{
					padding: 10px;
					color: #000;
					cursor: pointer;
				}
			}
			
			.cd_content{
				width: 320px;
				padding: 10px;
				background: #fff;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
				
				.cd_ct_pcb{
					display: flex;
					width: 100%;
					font-size: 6px;
					
					.cd_ct_pcb_title1{
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_title2{
						margin-left: 20px;
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_content1{
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct1_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct1_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct1_popover{
							position: absolute;
							top:32px;
							left: 0;
							width: 400px;
							border: 1px solid #d6d6d6;
							background: #fff;
							padding: 20px;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_content2{
						margin-left: 20px;
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct2_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct2_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct2_popover2{
							position: absolute;
							top:32px;
							left: 0;
							width: 100%;
							border: 1px solid #d6d6d6;
							background: #fff;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_input1{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_x{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input2{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_cm{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input3{
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
				}
				
				.cd_ct_button{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.cd_ct_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.block {
			position: relative;
			z-index: 2;
		}
		
		.small {
			// background: red;
			height: 100%;
		}
		
		input[type=text]:focus {
			outline: none;
			
		}
		
		.carouselDiv{
			position: absolute;
			left: 200px;
			height: 100%;
			display: flex;
			align-items: center;
			z-index: 6;
			
			.cd_tabs{
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
				display: flex;
				background: #c9caca;
				
				.cd_tabs_name{
					padding: 16px 26px;
					color: #000;
					cursor: pointer;
				}
			}
			
			.cd_content{
				width: 100%;
				padding: 20px;
				background: #fff;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
				
				.cd_ct_pcb{
					display: flex;
					width: 100%;
					
					.cd_ct_pcb_title1{
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_title2{
						margin-left: 20px;
						padding-bottom: 10px;
					}
					
					.cd_ct_pcb_content1{
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct1_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct1_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct1_popover{
							position: absolute;
							top:32px;
							left: 0;
							width: 400px;
							border: 1px solid #d6d6d6;
							background: #fff;
							padding: 20px;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_content2{
						margin-left: 20px;
						display: flex;
						cursor: pointer;
						position: relative;
						
						.cd_ct_pcb_ct2_input{
							width: 100%;
							height: 32px;
							line-height: 32px;
							text-align: center;
							border: 1px solid #d6d6d6;
							background: #fff;
							cursor: pointer;
						}
						
						.cd_ct_pcb_ct2_v{
							display: inline-block;
							font-size: 16px;
							border: 1px solid #d6d6d6;
							text-align: center;
							background: #efefef;
							padding: 0 10px;
							display: flex;
							align-items: center;
						}
						
						.cd_ct_pcb_ct2_popover2{
							position: absolute;
							top:32px;
							left: 0;
							width: 100%;
							border: 1px solid #d6d6d6;
							background: #fff;
							z-index: 2;
						}
					}
					
					.cd_ct_pcb_input1{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_x{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input2{
						width: 62px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
					
					.cd_ct_pcb_cm{
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d6d6d6;
						text-align: center;
						background: #efefef;
						padding: 0 10px;
						display: flex;
						align-items: center;
					}
					
					.cd_ct_pcb_input3{
						height: 32px;
						line-height: 32px;
						text-align: center;
						border: 1px solid #d6d6d6;
						background: #fff;
					}
				}
				
				.cd_ct_button{
					position: relative;
					padding-top:20px;
					text-align: right;
					z-index: 1;
					
					.cd_ct_text{
						padding: 10px 40px;
						cursor: pointer;
						display: inline-block;
						font-size: 16px;
						border: 1px solid #d1102d;
						background: #d1102d;
						color: #fff;
						box-shadow: 0 5px 7px rgb(255 104 34 / 28%);
						border-radius: 100px;
					}
				}
			}
		}
	}
	
</style>
